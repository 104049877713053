<template>
    <swiper :pagination="pagination" :modules="modules" v-if="reserves.length" @slide-change="onSlideChange">
            <swiper-slide
                v-for="reserve in reserves"
                :key="reserve"
                :data-title="reserve.status"
            >
                <p
                    v-for="res in reserve.reserves"
                    :key="res"
                >
                    <ReserveComponent
                        :data="res"
                    />
                </p>
            </swiper-slide>
        </swiper>
</template>

<script>
import ReserveComponent from "./ReserveComponent.vue";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { apiService } from "../../../util/api.service";
import { ref } from "vue";

export default {
  name: "ReserveListSwiperComponent",
  components: {
        ReserveComponent,
        Swiper,
        SwiperSlide,
  },
  methods: {
    onSlideChange() {
      let target = document.getElementsByClassName("header")[0];
      this.animate(document.scrollingElement || document.documentElement, "scrollTop", "", 0, target.offsetTop, 250, true);
    },
    animate: function(elem, style, unit, from, to, time, prop) {
      if (!elem) {
        return;
      }
      var start = new Date().getTime(),
        timer = setInterval(function() {
          var step = Math.min(1, (new Date().getTime() - start) / time);
          if (prop) {
            elem[style] = (from + step * (to - from)) + unit;
          } else {
            elem.style[style] = (from + step * (to - from)) + unit;
          }
          if (step === 1) {
            clearInterval(timer);
          }
        }, 25);
      if (prop) {
        elem[style] = from + unit;
      } else {
        elem.style[style] = from + unit;
      }
    }
  },
  async setup() {
    var reserves = ref([]);
    await apiService.getReserveList((response) => {
          reserves.value = response.data;
          reserves.value = reserves.value.filter((x) => { return x.reserves.length > 0});
      }
    );
    return {
      reserves,
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
            if (reserves.value != undefined && typeof reserves.value[index] !== "undefined") {
            return (
              '<span class="' +
              className +
              '">' +
              reserves.value[index].status +
              "</span>"
            );
          }
        },
      },
      modules: [Pagination],
    };
  },
};
</script>