<template>
  <div class="quotaBalancePage">
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Minhas cotas'"/>
    <h1>Saldo/Extrato</h1>
    <hr>
    <div v-for="quota in quotaInfo" :key="quota" class="quota-balance-div">
      <QuotaBalance :data="quota" @click="$router.push('/quota/balance/'+quota.id)"/>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import { apiService } from "@/util/api.service";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "@/assets/css/global.css"
import QuotaBalance from "./components/QuotaBalance.vue"

export default {
  name: "QuotaBalancePage",
  components: {
        HeaderLogo,
        QuotaBalance
    },
  data() {
    return {
        quotaInfo: [],
    }
  },
  mounted() {
    apiService.getQuotaDex((response) => {
        response.data.forEach((quota) => {
            let object = {
                id: quota.id,
                name: quota.name,
                code: quota.code,
                photo: quota.photo,
                dex: quota.dex
            }
            this.quotaInfo.push(object);
        });
    });
  },
  created() {
    if (localStorage.getItem("user_type") != "Client") {
      this.$router.push("/");
    }
  },
};
</script>

<style>
  h1 {
    font: normal normal bold 24px Helvetica Neue;
    color: black;
    text-align: center;
  }
  .quotaBalancePage > hr {
    position:relative;
    background: #4E4E4E 0% 0% no-repeat padding-box;
    width:26px;
    height: 5px;
    opacity: 1;
    border-radius: 10px;
    margin: auto auto 20px;
  }
  .quota-balance-div {
    margin-bottom: 30px;
  }
  .quota-balance-div:last-of-type {
    margin-bottom: 60px;
  }
</style>

<style scoped>
#app {
  padding-bottom: 30px;
}
</style>