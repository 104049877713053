<template>
    <div id="dropdown-menu" class="dropdownmenu-wrapper">
        <div class="menu-header" id="page-wrap">
            <div class="profile-image">
                <img :src="profilePhoto" alt="Avatar" />
            </div>
            <div class="profile-info">
                <p class="greeting-text" style="text-align: left; font-size: 23px;"><span>Olá, {{ username }}!</span>
                </p>
                <p style="text-align: left"><span>Cotista</span></p>
                <p style="text-align: left">
                    <router-link to="/changepassword">
                        <button class="changepass-button" @click="changePassword">
                            Alterar senha
                        </button>
                    </router-link>
                </p>
            </div>
        </div>
        <hr />
        <div class="menu-body">
            <ul class="menu-items">
                <li>
                    <router-link to="/account" style="text-decoration: none;">
                        <svg class="img-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                            <path
                                d="M61.44,0A61.31,61.31,0,0,1,84.92,4.66h0A61.66,61.66,0,0,1,118.21,38l.1.24a61.39,61.39,0,0,1-.1,46.73h0A61.42,61.42,0,0,1,38,118.21h0A61.3,61.3,0,0,1,18,104.88l0,0A61.5,61.5,0,0,1,4.66,84.94l-.09-.24A61.48,61.48,0,0,1,4.66,38v0A61.37,61.37,0,0,1,18,18l0,0A61.5,61.5,0,0,1,37.94,4.66l.24-.09A61.35,61.35,0,0,1,61.44,0ZM48.78,79.89a16.44,16.44,0,0,1-1.34-1.62c-2.6-3.56-4.93-7.58-7.27-11.33-1.7-2.5-2.59-4.73-2.59-6.52s1-4.13,3-4.64a101,101,0,0,1-.18-11.73A16.86,16.86,0,0,1,41,41.11a17,17,0,0,1,7.58-9.64,19.26,19.26,0,0,1,4.11-2c2.59-1,1.34-4.91,4.19-5C63.54,24.33,74.52,30,78.8,34.68a16.91,16.91,0,0,1,4.38,11l-.27,10.57a3.31,3.31,0,0,1,2.41,2.41c.36,1.43,0,3.39-1.25,6.16h0c0,.09-.09.09-.09.18-2.75,4.53-5.62,9.78-8.78,14-1.59,2.12-2.9,1.75-1.54,3.78,6.45,8.87,19.18,7.64,27,13.55a52.66,52.66,0,0,0,9.36-54.72l-.09-.2A52.7,52.7,0,0,0,98.55,24.33h0a52.63,52.63,0,0,0-57-11.49l-.21.09a52.53,52.53,0,0,0-17,11.4h0a52.63,52.63,0,0,0-11.49,57l.09.21A52.66,52.66,0,0,0,22.19,96.3c7.85-5.91,20.58-4.68,27-13.55,1.12-1.68.83-1.52-.44-2.86Z" />
                        </svg>
                        <span class="text-menu">Meus Dados</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/balance" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/dex.png" />
                        <span class="text-menu">Extrato Dex</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/quotas" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/quotas.png" />
                        <span class="text-menu">Cotas</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/reserves" style="text-decoration: none;">
                        <svg class="img-menu" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.1116 20.385C4.58872 20.385 0.111572 15.9079 0.111572 10.385C0.111572 4.86216 4.58872 0.38501 10.1116 0.38501C15.6344 0.38501 20.1116 4.86216 20.1116 10.385C20.1055 15.9053 15.6319 20.3789 10.1116 20.385ZM10.0956 18.385H10.1116C14.5283 18.3806 18.1058 14.7977 18.1036 10.381C18.1014 5.9643 14.5203 2.38501 10.1036 2.38501C5.68686 2.38501 2.10578 5.9643 2.10357 10.381C2.10136 14.7977 5.67886 18.3806 10.0956 18.385ZM8.11157 15.385L4.11157 11.385L5.52157 9.97501L8.11157 12.555L14.7016 5.96501L16.1116 7.38501L8.11157 15.385Z"
                                fill="black" />
                        </svg>
                        <span class="text-menu">Reservas</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/last-active-confirmation-code" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/key.png" />
                        <span class="text-menu">Código validador</span>
                    </router-link>
                </li>
            </ul>
            <hr />
            <div class="leave-app">
                <a href="#" @click="logout">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.1116 18.4362H7.11157C6.007 18.4362 5.11157 17.5408 5.11157 16.4362V12.4362H7.11157V16.4362H16.1116V2.43625H7.11157V6.43625H5.11157V2.43625C5.11157 1.33168 6.007 0.436249 7.11157 0.436249H16.1116C17.2161 0.436249 18.1116 1.33168 18.1116 2.43625V16.4362C18.1116 17.5408 17.2161 18.4362 16.1116 18.4362ZM9.11157 13.4362V10.4362H0.111572V8.43625H9.11157V5.43625L14.1116 9.43625L9.11157 13.4362Z"
                            fill="black" />
                    </svg>
                    <span class="text-menu">Sair do app</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { apiService } from "../util/api.service";

export default {
    name: "DropdownMenu",
    computed: {
        username: function () {
            return localStorage.getItem('username') != undefined ? localStorage.getItem('username') : "Fulano";
        },
        profilePhoto: function () {
            if (localStorage.getItem('profilePhoto') != "null" && localStorage.getItem('profilePhoto') != undefined) {
                return apiService.baseURL + localStorage.getItem('profilePhoto');
            }
            return require("../assets/img/img_avatar.png");
        },
    },
    methods: {
        toggleMenu(menuState) {
            let el = document.getElementById("dropdown-menu");
            if (menuState) {
                el.style.top = '42px';
                el.style.visibility = 'visible';
            } else {
                el.style.top = '-400px';
                el.style.visibility = 'hidden';
            }
        },
        logout() {
            apiService.logout(
                () => {
                    console.log("Deslogando usuário logado");
                    this.$router.push("/");
                },
                () => {
                    console.log("Nenhum usuário logado");
                    this.$router.push("/");
                }
            );
        },
    },
}
</script>

<style>
.dropdownmenu-wrapper {
    position: absolute;
    width: 100%;
    height: 550px;
    top: -300px;
    visibility: hidden;
    background: #EBEEF2;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 30px 30px;
    z-index: 100;
    transition: all 0.4s ease-out;
}

.header .bm-menu {
    padding-top: 0px !important;
    border-radius: 0px 20px 20px 0px;
    opacity: 0.98;
}

.header .bm-item-list {
    margin-left: 0px !important;
    height: 100%;
    overflow-y: hidden;
}

.header .bm-item-list>* {
    padding: 0px;
}

.header .bm-burger-bars {
    border-radius: 10px;
    height: 5px;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

#dropdown-menu {
    text-align: left;
}

.greeting-text {
    font-family: 'Alfa Slab One', cursive;
    color: black;
}

.leave-app {
    margin: 30px 0px 30px 36px;
    /* Remove margins */
}

.leave-app a {
    font-size: 18px !important;
    text-decoration: none !important;
    color: black !important;
}

hr {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #D1D1D1;
}

.menu-header {
    margin-left: 43px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 200px;
    border-radius: 0px 20px 0px 0px;
    opacity: 0.98;
}

.menu-body {
    border-radius: 0px 0px 20px 0px;
    opacity: 0.98;
    height: 100%;
}

ul.menu-items {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove padding */
    margin: 30px 0px 30px 36px;
    /* Remove margins */
}

.menu-items>li {
    margin-bottom: 12px;
}

.menu-items>li:last-child {
    margin-bottom: 0px;
}

.profile-image {
    width: 90px;
    height: 90px;
    margin-top: 24px;
}

.profile-image img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.profile-info {
    margin: 24px auto 0 24px;
    font-size: 16px;
    color: black;
}


.profile-info p {
    padding-top: 0px;
    margin-bottom: 0px;
}

.logout-button {
    font-weight: bold;
    font-size: 11px;
    flex: 1 0 21%;
    margin: 0px 36px;
    text-align: center;
    max-width: 120px;
    border-radius: 4px;
}

.changepass-button {
    font-weight: bold;
    font-size: 11px;
    flex: 1 0 21%;
    margin-top: 8px;
    text-align: center;
    width: 120px;
    height: 26px;
    border: 1px solid #000000;
    border-radius: 40px;
}

.logout-button svg {
    margin-right: 4px;
    margin-bottom: 2px;
}

.img-menu {
    position: relative;
    height: 20px !important;
    width: 20px !important;
    vertical-align: sub;
}

.text-menu {
    position: relative;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px;
    text-decoration: none;
    color: black;
    margin-left: 20px;
}
</style>