<template>
    <div class="card">
        <div class="card-body text-center">
            <div class="dex-description">
                <div class="dex-info">
                    <span class="asset-name">{{ data.asset_name }}</span> - <span id="dex-date"> {{ this.formatDate(data.date) }} </span><br />
                    <span class="quota-description">{{ data.description }}</span> <span v-bind:style="[(data.value>0) ? {'color':'green'} : {'color':'red'}]">{{ data.value }} Dex</span><br />
                    <hr/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DexBalance",
    props: {
        data: { type: Object },
    },
    methods: {
        formatDate(dateString) {
            let date = new Date(dateString)
            return date.toLocaleDateString('pt-BR', { month:"short", day:"numeric"}).slice(0, -1)
        },
    }
};

</script>

<style scoped>
.card {
    border-width: 0px;
}

.card-body {
    padding: 0 !important;
}

.dex-description {
    display: flex;
    margin: 0 16px 0 16px;
}

.dex-info {
    text-align: left;
    flex-shrink: 0;
    flex-grow: 1;
}

.dex-info span:not(:first-child) {
    display: inline-block;
    font-weight: 500;
}

#dex-date {
    color: #9c9c9c;
    font-weight: bold;
}

.asset-name {
    font-weight: bold !important;
    color: black;
}

.quota-description {
    font-weight: bold !important;
    color: #5c5c5c;
    padding-right: 5px;
}


@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
