<template>
  <div>
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Minhas cotas'"/>
    <Suspense>
      <template #default>
        <QuotaSwiperComponent/>
      </template>
      <template #fallback>
        <div class="text-center" style="margin-top: 15px;">Loading ...</div>
      </template>
    </Suspense>
  </div>
</template>
<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import QuotaSwiperComponent from "./components/QuotaSwiperComponent.vue";
import { apiService } from "../../util/api.service";
import { Suspense } from "vue";

import "swiper/css";
import "swiper/css/pagination";
import "../../assets/css/quotas.css";

export default {
  name: "QuotasPage",
  components: {
    HeaderLogo,
    QuotaSwiperComponent,
    Suspense
  },
  created() {
    if (localStorage.getItem("user_type") != "Client") {
      this.$router.push("/");
    }
  },
  methods: {
    getBackend: function() {
      return apiService.baseURL;
    },
  },
};

</script>


<style>
#app {
  background-color:#E2E6EB;
}
.swiper-slide {
  background-color: #E2E6EB;
  overflow: hidden;
}
.swiper-pagination-lock{
  display: block;
}
.swiper-pagination-bullet:only-child{
  display: inline-block !important;
}
</style>