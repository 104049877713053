<template>
  <div>
    <OperatorHeader v-if="isOperator" :showSideMenu="true" />
    <HeaderLogo v-if="!isOperator" :loginVariant="false" :showDexTotal="true" :pageTitle="'Alterar senha'"/>
    <div class="quota-title">Alterar Senha</div>
    <div class="quota-title-spacer"></div>
    <Suspense>
      <template #default>
        <form
          method="POST"
          class="text-center mt-4"
          @submit.prevent="changePassword"
        >
          <p>Digite sua senha atual:</p>
          <input
            type="password"
            name="current_password"
            v-model="input.current_password"
            placeholder="Senha Atual"
            class="rounded-input text-center black-outline"
            required
          />

          <p>Digite sua nova senha</p>
          <input
            type="password"
            name="password1"
            v-model="input.password1"
            placeholder="Senha Atual"
            class="rounded-input text-center black-outline"
            required
          />

          <p>Confirme sua nova senha</p>
          <input
            type="password"
            name="password2"
            v-model="input.password2"
            placeholder="Senha Atual"
            class="rounded-input text-center black-outline"
            required
          />
          <br />
          <div class="actions text-center">
            <button @click="makeReserve" class="btn button-black rounded-button">
              Enviar
            </button>
          </div>
        </form>
      </template>
    </Suspense>
    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500" transition="custom-from-bottom-transition">
      <div v-bind:class="[success ? 'wrapper wrapper-success' : 'wrapper wrapper-failure']">
        <div class="content-modal">
          <div class="title">
            {{success ? message_success : message_error}}
          </div>
        </div>
        <div class="actions-modal">
          <button
            class="btn btn-circle button-black modal-button rounded-button"
            @click="modalAction()"
          >
            OK
          </button>
        </div>
      </div>
    </GDialog>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import OperatorHeader from "../operator/components/OperatorHeader.vue";
import { apiService } from "../../util/api.service";

export default {
  name: "ChangePassword",
  components: {
    HeaderLogo,
    OperatorHeader,
  },
  data() {
    return {
      input: {
        current_password: "",
        password1: "",
        password2: "",
      },
      error: false,
      success: false,
      message_error: this.error,
      message_success: "Sua senha foi alterada com sucesso!",
      isOperator: false,
      dialogState: false,
    };
  },
  mounted() {
    if (localStorage.getItem("user_type") == "Operator") {
      this.isOperator = true;
    }
  },
  methods: {
    modalAction() {
      this.dialogState = false;
      if (this.success) {
        this.$router.push("/quotas");
      }
    },
    changePassword() {
      if (this.input.current_password != "" && this.input.password1 != "" && this.input.password2 != "") {
        let loader = this.$loading.show({
          container: null,
          canCancel: false,
        });
        this.error = false;
        apiService.postChangePassword(
          this.input.current_password,
          this.input.password1,
          this.input.password2,
          () => {
            loader.hide();
            this.error = false;
            this.success = true;
            this.dialogState = true;
          },
          (result) => {
            loader.hide();
            this.message_error = result.response.data.message;
            this.error = true;
            this.dialogState = true;
          }
        );
      } else {
        this.message_error = "Complete todos os campos necessários.";
        this.error = true;
      }
    },
    backToLogin() {
      this.$router.push("/quotas");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*,
/*resetar o estilo da página*/
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

p {
  text-align: center;
  font: normal normal bold 14px/20px Helvetica Neue;
  letter-spacing: 0px;
  color: #3b3b3b;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.error {
  color: red;
  font: normal normal bold 12px/14px Helvetica Neue;
}

.black-outline {
  border: 1px solid #000;
}


.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure{
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.content-modal {
  padding: 20px;
  margin: 50px 0 50px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
}

.actions-modal {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-top: 0px;
  margin: 30px auto 20px;
}

.modal-button {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
