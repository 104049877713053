<template>
  <div class="simulator-choice">
    <SimulatorHeader />
      <div class="content-container">
        <h1 class="simulator-title">Qual simulado você deseja fazer?</h1>
        <div class="card-container">
          <div class="card" @click="handleCardClick('MOTONAUTA')">
            <img src="@/assets/img/motonauta_icon.png" alt="motonauta">
            <h2 class="card-title">Motonauta</h2>
          </div>
          <div class="card" @click="handleCardClick('ARRAIS_AMADOR')">
            <img src="@/assets/img/arrais_icon.png" alt="arrais amador">
            <h2 class="card-title">Arrais amador</h2>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import SimulatorHeader from "./SimulatorHeader.vue";
import { apiService } from "../../util/api.service";

export default {
  name: "SimulatorChoice",
  components: {
    SimulatorHeader,
  },
  data() {},
  mounted() {
    if (localStorage.getItem("email") == undefined) {
      return this.$router.push("/simulado");
    }
  },
  methods: {
    async handleCardClick(simulator_type) {
      await apiService.generateSimulator(localStorage.getItem("email"), simulator_type, (response) => {
        localStorage.setItem("questions", JSON.stringify(response.data["questions"]));
        localStorage.setItem("simulatorState", JSON.stringify({}));
        localStorage.setItem("passingScore", JSON.stringify(response.data["passing_score"]));
        return this.$router.push("/simulado/perguntas");
      });
    }
  }
};
</script>

<style scoped>
.simulator-title {
  color: #4D4D4D;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  padding: 32px 24px;
  background-color: #ecf2ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container {
  display: flex;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 163px;
  height: 163px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FCFCFC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.card:active {
  transform: translateY(5px);
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

.card:focus {
  transform: translateY(5px);
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

.card img {
  width: 131px;
  height: 85px;
  flex-shrink: 0;
}

.card-title {
  color: #4D4D4D;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .content-container {
    border-radius: 20px;
    padding-bottom: 37px;
  }

  .card {
    box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.20);
  }
}
</style>