<template>
  <div class="reservedetails vh-100 vw-100">
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Minhas cotas'"/>
    <div class="asset">
      <AssetComponent @updateDate="updateDate" v-if="isLoaded" :data="quota" />
      <loading v-model:active="loading" :can-cancel="false" :is-full-page="true"></loading>
      <div @click="makeReserve" :disabled="loading" class="reservar">
        Salvar
      </div>
    </div>


    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500"
      transition="custom-from-bottom-transition">
      <div v-bind:class="[successfull ? 'wrapper wrapper-success' : 'wrapper wrapper-failure']">
        <div class="content-modal">
          <div class="title">
            {{ title }}
          </div>
          <div class="message">
            {{ message }}
          </div>
        </div>
        <div class="actions-modal">
          <button class="btn btn-circle button-black modal-button rounded-button" @click="modalAction">
            OK
          </button>
        </div>
      </div>
    </GDialog>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import AssetComponent from "./components/AssetComponent.vue";
import { apiService } from "../../util/api.service";
import VueLoading from 'vue-loading-overlay';

export default {
  name: "CreateReserve",
  components: {
    HeaderLogo,
    AssetComponent,
    Loading: VueLoading,
  },
  props: {
    quota_id: String
  },
  data() {
    return {
      quota: undefined,
      isLoaded: false,
      date_list: [],
      dialogState: false,
      successfull: false,
      loading: false,
    }
  },
  async mounted() {
    this.loading = false;
    this.isLoaded = false;
    await apiService.getQuota(this.quota_id, (response) => {
      this.quota = response.data;
    });
    this.isLoaded = true;    
  },
  methods: {
    back() {
      this.$router.push("/quotas");
    },
    updateDate(newDate) {
      if (!this.date_list.includes(newDate)) {
        this.date_list.push(newDate)
      } else {
        this.date_list = this.date_list.filter(x => x != newDate)
      }
    },
    async makeReserve() {
      if (this.date_list != undefined) {
        this.loading = true;
        await apiService.postReserveAsset(
          this.date_list,
          this.quota.id,
          (response) => {
            this.message = response.data.message
            this.title = response.data.title
            if (response.data.reserve != undefined && response.data.reserve.length > 0) {
              this.successfull = true;
            } else {
              this.successfull = false;
            }
            this.loading = false;
          },
          (error) => {
            this.title = error.response.data.title;
            this.message = error.response.data.message;
            this.successfull = false;
            this.loading = false;
          });
        this.dialogState = true;
      }
    },
    modalAction() {
      this.dialogState = false;
      if (this.successfull) {
        this.$router.push("/quotas");
      }
    }
  },
};
</script>

<style>
.bottomModal {
  margin: auto auto 0 auto !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25) !important;
  border-radius: 15px 15px 0px 0px !important;
  transform: translateY(0px);
}

.custom-from-bottom-transition,
.custom-from-bottom-transition-enter-from {
  transform: translate(0, 100%);
  opacity: 0;
}

.custom-from-bottom-transition-leave-to {
  transform: translate(0, 50%);
  opacity: 0;
}

.g-dialog-content {
  max-width: none !important;
}

.g-dialog-overlay {
  background: #dbdbdb82 !important;
}

.vc-container {
  background-color: #EFF1F3 !important;
  border-color: #EFF1F3 !important;
}

.vc-title {
  text-transform: capitalize;
}

.vc-weekday {
  padding-bottom: 16px !important;
  border-bottom: 1px solid #D1D3D4;
  text-transform: capitalize;
}
</style>

<style scoped>

.reservedetails {
  text-align:center;
}

.asset {
  margin-top: 18px;
}

.reservar {
  max-width: 180px;
}

.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure {
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.content-modal {
  padding: 20px;
  margin: 50px 0 50px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
  margin-bottom: 42px;
}

.message {
  font-family: 'Open Sans', sans-serif;
  color: black;
  letter-spacing: 0px;
  margin: auto;
  font-size: 18px;
  text-align: center;
}

.actions-modal {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-top: 0px;
  margin: 30px auto 20px;
}

.modal-button {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>