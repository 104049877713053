<template>
  <div class="calendarcomponent">
    <v-date-picker ref="datePicker" trim-weeks v-model="date" @dayclick="selectDate"
      @update:from-page="fromPageFunction" :min-date="new Date()" :disabled-dates="disabledDates"
      :columns="layout.columns" :rows="layout.rows" :is-expanded="layout.isExpanded" :attributes="attrs"
      :firstDayOfWeek="2" locale="pt-BR" :masks="masks" />
    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500"
      transition="custom-from-bottom-transition">
      <div v-bind:class="[successfull ? 'wrapper wrapper-success' : 'wrapper wrapper-failure']" @close="closeDialog()">
        <div class="content-modal">
          <div class="title" ref="modalTitle">
            Saldo de dias {{ type }} insuficiente.<br />
          </div>
        </div>
        <div class="actions-modal actions">
          <button ref="modalButton" class="btn button-black rounded-button" @click="dialogState = false">
            OK
          </button>
        </div>
      </div>
    </GDialog>
  </div>
</template>

<script>
import { apiService } from "../../../util/api.service";

export default {
  name: "CalendarComponent",
  props: {
    asset_id: undefined,
    total_days_balance: undefined,
    week_days_balance: undefined,
    free_days_balance: undefined,
  },
  data() {
    return {
      days: [],
      date: undefined,
      month: undefined,
      week_days_selected: 0,
      free_days_selected: 0,
      year: undefined,
      dateColors: undefined,
      loadedColors: false,
      attrs: undefined,
      disabledDates: [],
      dialogState: false,
      type: "de semana",
      successfull: false,
    };
  },
  methods: {
    selectDate(value) {
      this.setAttrs();
      this.dialogState = false;
      const selectedDate = this.days.find((day) => day.id === value.id);
      const dayOfWeek = value.date.getDay(); // 6 = Saturday, 0 = Sunday
      let colorSelected = dayOfWeek === 6 || dayOfWeek === 0 ? "#5764EE" : "#60CC9F";
      const weekend = dayOfWeek === 6 || dayOfWeek === 0;

      if (value.isDisabled) {
        return;
      }

      if (!selectedDate) {
        if (![0, 6].includes(dayOfWeek)) {
          if (this.week_days_selected + this.free_days_selected === this.total_days_balance) {
            this.type = "de semana e livres";
            this.dialogState = true;
            this.successfull = false;
            return;
          }
        } else {
          if (this.free_days_selected === this.free_days_balance) {
            this.type = "livres";
            this.dialogState = true;
            this.successfull = false;
            return;
          }
        }

        let freeDay = false;
        if (!weekend && this.week_days_selected < this.week_days_balance) {
          this.week_days_selected += 1;
          freeDay = false;
        } else if (weekend || (this.week_days_selected >= this.week_days_balance && !weekend)) {
          this.free_days_selected += 1;
          freeDay = true;
        }

        this.days.push({
          id: value.id,
          date: value.date,
          color: colorSelected,
          weekend: weekend,
          creditType: freeDay,
        });
      } else {
        const index = this.days.indexOf(selectedDate);
        const creditType = selectedDate.creditType;

        this.days.splice(index, 1);

        if (creditType) {
          this.free_days_selected -= 1;
        } else {
          this.week_days_selected -= 1;
        }
      }

      this.updateColors();
      this.emitEvents(value);
    },
    async fromPageFunction(page) {
      this.month = page.month;
      this.year = page.year;
      await apiService.getReservedDays(
        this.month,
        this.year,
        this.asset_id,
        (response) => {
          this.dateColors = response.data;
        }
      );

      this.loadedColors = true;

      const localOffset = new Date().getTimezoneOffset() * 60000;
      this.disabledDates = this.dateColors
        .flatMap((dateTaken) => dateTaken.days.map((date) => new Date(new Date(date).getTime() + localOffset)));

      this.attrs = this.attributes.concat(
        this.dateColors.map((dateTaken) => ({
          key: dateTaken.name,
          highlight: {
            contentClass: "is-disabled taken-date",
          },
          dates: dateTaken.days.map((date) => new Date(new Date(date).getTime() + localOffset)),
        }))
      );
    },
    updateColors() {
      this.$nextTick(() => {
        const datePicker = this.$refs.datePicker;
        if (datePicker && datePicker.$el) {
          this.days.forEach((el) => {
            const selectedDay = datePicker.$el.querySelector(`.id-${el.id}`);
            if (selectedDay) {
              selectedDay.style.backgroundColor = el.color;
            }
            const selectedDayHighlight = datePicker.$el.querySelector(`.id-${el.id} .vc-highlight`);
            if (selectedDayHighlight) {
              selectedDayHighlight.style.backgroundColor = el.color;
            }
          });
        }
      });
    },
    setAttrs() {
      this.attrs = this.attributes.concat(
        this.dateColors.map((dateTaken) => ({
          key: dateTaken.name,
          highlight: {
            contentClass: "is-disabled taken-date",
          },
          dates: dateTaken.days.map((date) => new Date(date + "T00:00:00-03:00")),
        }))
      );
    },
    emitEvents(value) {
      if (value.date >= new Date().setHours(0, 0, 0, 0)) {
        this.$emit("changeDay", value.id);
      } else {
        this.$emit("changeDay", undefined);
      }

      this.$emit("updateFreeDaysSelected", this.free_days_selected);
      this.$emit("updateWeekDaysSelected", this.week_days_selected);
    },
  },
  computed: {
    masks() {
      return {
        weekdays: "WWW",
      };
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        key: date,
        highlight: true,
        dates: date,
      }));
    },
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 4,
          rows: 2,
          isExpanded: false,
        },
      });
    },
  },
};
</script>

<style>
.taken-date {
  color: red !important;
}

.vc-day {
  background-color: transparent !important;
}

.title {
  font: normal normal 900 24px/28px Helvetica Neue;
  color: black;
  letter-spacing: 0px;
  margin-bottom: -8px;
  margin: auto;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  /* margin-bottom: 20px; */
}

.btn:hover {
  color: #ffffff;
}

.unselected {
  color: black !important;
  font-weight: 0;
}

.actions-modal {
  display: flex;
  justify-content: center;
  border-top: 0px;
  margin: 30px auto 20px;
}

.content-modal {
  padding: 20px;
}

.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure {
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.content-modal {
  padding: 20px;
  margin: 50px 0 50px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
}

.modal-button {
  font-family: 'Alfa Slab One', cursive;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

.button-black {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
