<template>
  <div class="header headerlogo vw-100">
    <div v-if="loginVariant" class="login-variant text-center">
      <img v-if="loginVariant" src="@/assets/img/logo_black.png" class="img-fluid" />
    </div>
    <div v-if="!loginVariant && !menuState" class="normal-variant text-center">
      <div class="menu-total">
        <img class="dex-logo" src="@/assets/img/dex.png"/>
        <div class="dex-text">
          <span style="color: #ABAAAA;">Saldo</span>
          <span style="color: #A86A00; font-weight: 700;">{{dexTotal}}</Span>
        </div>
      </div>
      <div class="menu-title">
        {{pageTitle}}
      </div>
      <div @click="showMenu()" class="menu-button">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.1992 18.2855H12.1992V16.3026H21.1992V18.2855ZM21.1992 13.3283H3.19922V11.3455H21.1992V13.3283ZM21.1992 8.37117H3.19922V6.38831H21.1992V8.37117Z" fill="black"/>
        </svg>
      </div>
    </div>
    <div v-show="!loginVariant && menuState" id="expanded-header" class="expanded-menu-header text-center">
      <div class="menu-title">
        <img class="efex-logo" src="@/assets/img/logo_black.png"/>
      </div>
      <div @click="showMenu()" class="menu-button">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.8908 0.872803L7.30078 6.4628L1.71078 0.872803L0.300781 2.2828L5.89078 7.8728L0.300781 13.4628L1.71078 14.8728L7.30078 9.2828L12.8908 14.8728L14.3008 13.4628L8.71078 7.8728L14.3008 2.2828L12.8908 0.872803Z" fill="black"/>
        </svg>
      </div>
    </div>  
  </div>
  <DropdownMenu v-if="!loginVariant" ref="dropdownMenu" v-show="showMenu"/>
</template>

<script>
import { apiService } from "@/util/api.service";
import DropdownMenu from "./DropdownMenu.vue";

export default {
  name: "HeaderLogo",
  props: {
    loginVariant: { required: false, type: Boolean, default: true },
    showDexTotal: { required: false, type: Boolean, default: false },
    pageTitle: { required: false, type: String, default: "" },
  },
  components: {
    DropdownMenu
  },
  data() {
    return {
      dexTotal: 0,
      menuState: false,
    }
  },
  methods: {
    showMenu() {
      this.menuState = !this.menuState
      this.$refs.dropdownMenu.toggleMenu(this.menuState)
    },
  },
  created() {
    if (this.showDexTotal) {
      apiService.getDexTotal((response) => {
        this.dexTotal = response.data.dex_total;
      });
    }
  }
};
</script>

<style scoped>

img {
  width: 172px;
}

.login-variant img {
  padding: 70px 20px 20px 20px;
}


.normal-variant {
  height: 75px;
  background: #FFFFFF;
  color: black;
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 14px;
  position: relative;
  display: flex;
  z-index: 200;
}

.normal-variant .menu-total {
  height: 28px;
  width: 33%;
  align-self: center;
  display:flex;
  padding-left: 5%;
}

.normal-variant .menu-total img {
  width: 28px;
  margin-right: 8px;
}


.normal-variant .menu-total .dex-text {
  display:flex;
  flex-direction: column; 
  text-align: left;
  margin-bottom: 2px;
}

.normal-variant .menu-total .dex-text span{
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
}


.normal-variant .menu-title {
  align-self: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 33%;
}

.normal-variant .menu-button {
  align-self: center;
  width: 33%;
}

.normal-variant .menu-button svg {
  margin-left: 60%;
}

.expanded-menu-header {
  height: 75px;
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 25px;
  z-index: 200;
  background: #FFFFFF;
  color: black;
  position: relative;
  display: flex;
  justify-content: right;
}

.expanded-menu-header .menu-title {
  width: 50%;
  align-self: center;
}

.expanded-menu-header .menu-title img {
  width: 140px;
}

.expanded-menu-header .menu-button {
  align-self: center;
  width: 25%;
}

.expanded-menu-header .menu-button svg {
  margin-left: 40%;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
</style>
