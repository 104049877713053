<template>
  <div class="login vh-100 vw-100">
    <HeaderLogo />
    <form method="POST" class="text-center mt-4" @submit.prevent="login">
      <p class="error" v-if="error">{{ login_error }}</p>
      <input
        type="email"
        name="email"
        v-model="input.email"
        placeholder="E-mail"
        class="rounded-input"
        style="margin-bottom: 21px; margin-top: 32px;"
        required
      />
      <input
        type="password"
        name="password"
        v-model="input.password"
        placeholder="Senha"
        class="rounded-input"
        ref="pw"
        required
      />
      <br />
      <button type="submit" class="btn rounded-button">Entrar</button>
      <br />
      <router-link to="/forgotpassword" class="forgot-password"
        >Esqueci a senha</router-link
      >
    </form>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import { apiService } from "../../util/api.service";

export default {
  name: "LoginPage",
  components: {
    HeaderLogo,
  },
  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      error: false,
      login_error: "*Usuário ou senha errados",
    };
  },
  created() {
    apiService.checkToken((result) => {
      if (result) {
        redirect_user(this)
      } else if (localStorage.getItem("refreshToken")) {
        apiService.refreshToken(
          (result) => {
            if (result) {
              localStorage.setItem("token", result.access);
              redirect_user(this)
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
            }
          },
          () => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
          }
        );
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }
    });
  },
  mounted() {
    if (localStorage.getItem("email")) {
      this.input.email = localStorage.getItem("email");
      this.$refs.pw.focus();
    }
  },
  methods: {
    login() {
      if (this.input.email != "" && this.input.password != "") {
        let loader = this.$loading.show({
          container: null,
          canCancel: false,
        });
        this.error = false;
        apiService.login(
          this.input.email,
          this.input.password,
          (result) => {
            loader.hide()
            this.error = false
            localStorage.setItem("email", this.input.email);
            if (result.data.user_type == "Operator") {
              this.$router.push("/operator/reserves")
            } else if (result.data.user_type == "Client") {
              this.$router.push("/quotas")
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("username");
              localStorage.removeItem("user_type");
              localStorage.removeItem("email");
              this.$router.push("/");
            }
          },
          (error) => {
            loader.hide();
            localStorage.clear();
            this.login_error = error.response.data.message;
            this.error = true;
          }
        );
      } else {
        this.login_error = "Necessário email e senha";
        localStorage.clear();
        this.error = true;
      }
    },
  },
};

function redirect_user(self) {
  if (localStorage.getItem("user_type") == "Operator") {
    self.$router.push("/operator/reserves");
  } else if (localStorage.getItem("user_type") == "Client"){
    self.$router.push("/quotas");
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    localStorage.removeItem("user_type");
    self.$router.push("/");
  }
}
</script>

<style scoped>
*, /*resetar o estilo da página*/
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.login {
  background: url("@/assets/img/login_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error {
  color: red;
  font: normal normal bold 12px/14px Helvetica Neue;
}

.login input {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000cc;
  padding: 10px 10px 10px 24px;
  width: 300px;
  height: 48px;
}

.login button {
  background: #000000;
  border-radius: 40px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 0px;
  color: #ffffff;
  width: 174px;
  margin-top: 28px;
}

.forgot-password {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  color: #3b3b3b;
}


@media screen and (min-width: 768px) {
  .login {
    background-position-y: 70%;
  }
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
</style>
