<template>
  <div class="simulator-choice">
    <SimulatorHeader />
    <div class="content-wrapper">
      <div class="main-content-container">
        <div v-if="status === 'aprovado'">
          <img src="@/assets/img/trophy_icon.png" alt="motonauta">
        </div>
        <div v-else>
          <img src="@/assets/img/life_preserver_icon.png" alt="motonauta">
          <img class="custom_icon" src="@/assets/img/anchor_icon.png" alt="motonauta">
        </div>
        <div class="content-container">
          <h1 class="simulator-title">{{ status === 'aprovado' ? 'Parabéns!' : 'Ah, não!' }}</h1>
          <h2 class="simulator-subtitle" :style="{ color: status === 'aprovado' ? '#1ABD3E' : '#DC3545' }">{{ status ===
            'aprovado' ? `Você acertou ${corrects} questões!` : `Você acertou só ${corrects} questões!` }}</h2>
          <p class="simulator-text">
            {{ status === 'aprovado' ? 'Esta quantidade de acertos é suficiente para conquistar a aprovação!' : 'Esta quantidade de acertos é insuficiente para conquistar a aprovação!' }}
          </p>
        </div>
        <button @click="retakeExam" type="submit" class="btn rounded-button btn-block">
          {{ status === 'aprovado' ? 'Melhorar meu resultado' : 'Tentar novamente' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SimulatorHeader from "./SimulatorHeader.vue";
export default {
  name: "SimulatorChoice",
  components: {
    SimulatorHeader,
  },
  props: {
    answers: Array
  },
  data() {
    return {
      status: 'aprovado',
      corrects: 0,
      passing_score: null,
    };
  },
  mounted() {
    const simulatorScore = JSON.parse(localStorage.getItem("passingScore"));
    const questions = JSON.parse(localStorage.getItem("questions"))
    if (!questions || !simulatorScore) return this.$router.push("/simulado/escolha");
    if (!this.answers) {
      return this.$router.push("/simulado/perguntas");
    }
    this.corrects = this.answers.filter((answer) => answer=="true" || answer==true).length;
    this.passing_score = simulatorScore;
    this.status = this.corrects >= this.passing_score ? "aprovado" : "reprovado";
  },
  methods: {
    retakeExam() {
      localStorage.removeItem("simulatorState");
      return this.$router.push("/simulado/perguntas");
    }
  }
};
</script>

<style scoped>
.btn-block {
  width: 100% !important;
}

.content-wrapper {
  height: 100vh;
}

.simulator-choice {
  background-color: #ecf2ff;
}

.simulator-title {
  color: #4D4D4D;
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.custom_icon {
  margin-left: -50px;
}

.simulator-subtitle {
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.5px;
}

.simulator-text {
  color: #4D4D4D;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.simulator-subtitle,
.simulator-text {
  margin: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-content-container {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.main-content-container button {
  display: flex;
  width: 300px;
  padding: 10px 16px;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #FFFF;
  border-radius: 6px;
  outline: none;
  background: #0050B7;
}

.main-content-container button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

@media screen and (min-width: 768px) {
  .main-content-container {
    border-radius: 20px;
    padding-bottom: 37px;
  }
}</style>