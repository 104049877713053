import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { createRouter, createWebHistory } from 'vue-router';
import { apiService } from './util/api.service';

import LoginPage from './screens/shared/LoginPage.vue';
import AccountPage from './screens/shared/AccountPage.vue';
import PrivacyTerms from './screens/shared/PrivacyTerms.vue';
import ForgotPassword from './screens/shared/ForgotPassword.vue';
import ResetPassword from './screens/shared/ResetPassword.vue';
import ChangePassword from './screens/shared/ChangePassword.vue';

import CreateReserve from "./screens/client/CreateReserve.vue";
import ReserveDetails from './screens/client/ReserveDetails.vue';
import ReserveList from './screens/client/ReserveList.vue';
import QuotasPage from './screens/client/QuotasPage.vue';
import QuotaBalancePage from './screens/client/QuotaBalancePage.vue';
import OperatorReserveList from "./screens/operator/OperatorReserveList.vue";
import OperatorCameraScanner from "./screens/operator/components/OperatorCameraScanner.vue";
import DexBalancePage from './screens/client/DexBalancePage.vue';
import LastActiveConfirmationCode from './screens/client/LastActiveConfirmationCode.vue';

import LeadRegistration from './screens/shared/LeadRegistration.vue';
import SimulatorChoice from './screens/simulator/SimulatorChoice.vue';
import SimulatorQuestions from './screens/simulator/SimulatorQuestions.vue';
import SimulatorResult from './screens/simulator/SimulatorResult.vue';

import VueLoading from 'vue-loading-overlay';
import VCalendar from 'v-calendar';
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import Maska from "maska";

import 'vue-loading-overlay/dist/vue-loading.css';
import "@/assets/css/global.css"
import 'v-calendar/dist/style.css';

const Home = { template: '<div><h1 class="text-center">Home - Logged</h1></div>' }

const routes = [
    { path: '/', component: LoginPage, name:"login", meta: {requiresAuth: false} },
    { path: '/privacyterms', component: PrivacyTerms, name:"privacy-terms", meta: {requiresAuth: false} },
    { path: '/home', component: Home, name:"home", meta: {requiresAuth: true} },
    { path: '/account', component: AccountPage, name:"account-page", meta: {requiresAuth: true} },
    { path: '/changepassword', component: ChangePassword, name:"change-pass", meta: {requiresAuth: true} },
    { path: '/forgotpassword', component: ForgotPassword, name:"forgot-pass", meta: {requiresAuth: false} },
    { path: '/resetpassword', component: ResetPassword, name:"reset-pass", meta: {requiresAuth: false} },
    { path: '/reservedetails', component: ReserveDetails, name:"reserve-details", meta: {requiresAuth: true} },
    { path: '/createreserve/:quota_id', component: CreateReserve, name:"create-reserve", props: true, meta: {requiresAuth: true} },
    { path: '/reserves', component: ReserveList, name:"reserve-list", meta: {requiresAuth: true} },
    { path: '/operator/reserves', component: OperatorReserveList, name:"operator-reserve-list", meta: {requiresAuth: false} },
    { path: '/quotas', component: QuotasPage, name:"quota-list", meta: {requiresAuth: true} },
    { path: '/balance', component: QuotaBalancePage, name:"quota-balance-list", meta: {requiresAuth: true} },
    { path: '/qrcodereader', component: OperatorCameraScanner, name:"qrcodereader", meta: {requiresAuth: true} },
    { path: '/quota/balance/:quota_id', component: DexBalancePage, name:"dex-balance-list", props: true, meta: {requiresAuth: true} },
    { path: '/last-active-confirmation-code', component: LastActiveConfirmationCode, name:"last-active-confirmation-code", props: true, meta: {requiresAuth: true} },
    { path: '/simulado', component: LeadRegistration, name:"lead-registration", meta: {requiresAuth: false}},
    { path: '/simulado/escolha', component: SimulatorChoice, name:"simulator-choice", meta: {requiresAuth: false}},
    { path: '/simulado/perguntas', component: SimulatorQuestions, name:"simulator-questions", meta: {requiresAuth: false}},
    { path: '/simulado/resultado', component: SimulatorResult, name:"simulator-result", props: true, meta: {requiresAuth: false}},
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes, // short for `routes: routes`
});



router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)){
        if (localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
            next({ path: "/" });
        } else {
            apiService.checkToken((result) => {
                if (result) {
                    next();
                } else {
                    apiService.refreshToken((result) => {
                        if (result) {
                            localStorage.setItem("token", result.access);
                            next();
                        } else {
                            localStorage.removeItem("token");
                            localStorage.removeItem("refreshToken");
                            next({ path: "/" });
                        }
                    }, () => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        next({path:"/"})
                    });
                }
            });
        }
    } else {
        next();
    }
    if (to.path.startsWith('/simulado')) {
        document.body.classList.add('simulator-body-style');
    }
});

const app = createApp({});

app.use(VCalendar, {});
app.use(VueLoading);
app.use(router);
app.use(Maska);
app.component('GDialog', GDialog)
app.mount('#app');
