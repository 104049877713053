<template>
  <div class="reservecomponent">
    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500"
      transition="custom-from-bottom-transition">
      <div v-bind:class="success ? 'wrapper wrapper-success' : 'wrapper wrapper-failure'" @close="closeDialog()">
        <div class="content-modal">
          <div class="title" ref="modalTitle">
            Deseja realmente cancelar a reserva {{ data.asset.name }} em
            {{ formatDate(data.reserve_date) }}?<br />
          </div>
        </div>
        <div class="actions-modal">
          <button ref="modalButton" class="btn btn-circle button-black modal-button rounded-button"
            @click="reserveCancel(data.id)">
            SIM
          </button>
          <button v-if="!canceled" class="btn btn-circle button-black modal-button rounded-button"
            @click="dialogState = false">
            NÃO
          </button>
        </div>
      </div>
    </GDialog>
    <GDialog :contentClass="'bottomModal'" v-model="dialogStateError" max-width="500"
      transition="custom-from-bottom-transition">
      <div class="wrapper wrapper-failure">
        <div class="content-modal">
          <div class="title">{{ errorMessage }}</div>
        </div>
        <div class="actions-modal">
          <button class="btn btn-circle button-black modal-button rounded-button"
            @click="dialogStateError = false">
            OK
          </button>
        </div>
      </div>
    </GDialog>
    <div class="card card-image">
      <div class="card-body text-center">
        <div class="asset-description">
          <img class="img" :src="getImage()" />
          <div class="asset-info">
            <div class="asset-header">
              <div class="category">{{ data.asset.category.name }}</div>
              <div class="dates">{{ formatDate(data.release_date) }} à {{ formatDate(data.receipt_date) }}</div>
            </div>
            <div class="asset-name">{{ data.asset.name }}</div><br />
            <div class="asset-time">
              <b>Horário</b>: {{ data.company_operation_time }}
              <button class="reserve-button" @click="modalAction()"
                v-if="!['CANCELADA', 'FINALIZADA'].includes(data.status)">
                <BIconX />Cancelar
              </button>
            </div><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import "@/assets/css/reserves.css"
import { BIconX } from "bootstrap-icons-vue";
import { apiService } from "../../../util/api.service";
import dayjs from "dayjs";

export default {
  name: "ReserveComponent",
  components: {
    BIconX,
  },
  data() {
    return {
      dialogState: false,
      dialogStateError: false,
      canceled: this.cancel_reserve,
      overlay: false,
      success: false,
      errorMessage: "",
    };
  },
  props: {
    data: { type: Object },
    cancel_reserve: { required: false, type: Boolean, default: false },
  },
  watch: {
    dialogState(visible) {
      if (!visible && this.canceled) {
        window.location.reload();
      }
    },
  },
  methods: {
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    getImage() {
      if (this.data.asset.photo) {
        return apiService.baseURL + "/" + this.data.asset.photo;
      }
      return require("@/assets/img/jetski.png");
    },
    modalAction() {
      this.dialogState = true;
    },
    moreInfo() {
      this.overlay = !this.overlay;
    },
    async reserveCancel(reserve_id) {
      this.dialogState = false;
      this.dialogStateError = false;
      await apiService.getChangeReserveStatus(reserve_id, "cancel", (response) => {
        this.message = response.data.message;
        this.canceled = true;
        this.success = true;
        this.$refs.modalTitle.innerText = this.message;
        this.$refs.modalButton.innerText = "OK";
        var modalButton = this.$refs.modalButton;
        var modalButtonClone = modalButton.cloneNode(true);
        modalButton.parentNode.replaceChild(modalButtonClone, modalButton);
        modalButtonClone.addEventListener(
          "click",
          function () {
            window.location.reload();
          },
          false
        );
        this.dialogState = true;
      },
        (response) => {
          this.errorMessage = response.response.data.message;
          this.dialogStateError = true;
        }
      );
    },
  },
};
</script>

<style scoped>
.reservecomponent {
  margin: 10px 4% 0px 4%;
}

.card {
  border-width: 0px;
}


.card-body {
  padding: 1rem 1rem !important;
}

.card-image {
  border-radius: 20px;
  z-index: 1;
  background-color: whitesmoke;
  overflow: hidden;
}

.card-image .img {
  max-height: 100px;
  min-height: 90px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  display: block;
  width: 120px !important;
  height: 100% !important;
  -o-object-fit: cover;
  border-radius: 15px;
  object-fit: cover;
}

.reserve-info {
  border-radius: 50%;
  position: absolute;
  color: black;
}

.reserve-button {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  margin-top: 3px;
  float: right;
  justify-content: center;
  align-items: center;
  padding: 2px 9px;
  width: 67px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 10px;
  right: 0px;
  font-size: 11px;
}

.asset-info {
  margin-left: 24px;
  position: relative;
  flex-grow: 2;
  text-align: left;
}

.asset-info>p {
  margin-bottom: 25px;
}

.asset-description {
  display: flex;
  min-height: 90px;
}

.asset-description>span {
  font-size: 14px;
  max-height: auto;
}

.asset-name {
  display: inline-block;
  color: black;
  font-weight: bolder;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: initial;
  margin-bottom: 16px;
  margin-top: 16px;
}

.asset-time {
  font-family: 'Open Sans', sans-serif;
  color: black;
  position: absolute;
  font-size: 14px;
  width: 100%;
}

.asset-header {
  display: flex;
  justify-content: space-between;
}

.dates {
  font-family: 'Open Sans', sans-serif;
  margin-left: 8px;
  font-size: 10px;
}

.category {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 0px 10px;
  display: table;
  border-style: none;
  height: 19px;
  color: black;
  border-color: #4e4e4e;
  border-radius: 10px;
  background: #E2E6EB 0% 0% no-repeat padding-box;
  align-self: center;
}

.actions-modal {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.title {
  font: normal normal 900 24px/28px Helvetica Neue;
  color: black;
  letter-spacing: 0px;
  margin-bottom: -8px;
  margin: auto;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  /* margin-bottom: 20px; */
}

.btn:hover {
  color: #ffffff;
}

.actions-modal {
  display: flex;
  justify-content: center;
  border-top: 0px;
  margin: 30px auto 20px;
}

.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure {
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.content-modal {
  padding: 20px;
  margin: 50px 0 50px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
}

.actions-modal {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-top: 0px;
  margin: 30px auto 20px;
}

.modal-button {
  font-family: 'Alfa Slab One', cursive;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

.button-black {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
