<template>
    <div class="card card-confirmation-code">
        <div class="card-body">
            <div v-if="data.action_type" class="asset-message">Você está <b>{{ data.action_type }}</b> o bem <b>{{
                data.asset_name
            }}</b></div>
            <div v-if="data.confirmation_code" class="confirmation-code">{{ data.confirmation_code }}</div>
            <div v-else class="no-code">Sem código ativo no momento</div>
            <div v-if="data.operator_name" class="operator-message">Informe o código ao operador <b>{{
                data.operator_name
            }}</b></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ConfirmationCode",
  props: {
    data: { required: true, type: Object, default: function() { return {}; } }
  },
}
</script>

<style scoped>
.card-confirmation-code {
  margin: 20px;
  border-radius: 10px;
  height: 150px;
}

.card-confirmation-code .card-body {
  display: grid;
  align-items: center;
}

.confirmation-code {
  font-size: 36px;
  font-weight: 800;
  line-height: 20px;
}

.no-code {
  font-size: 18px;
  font-weight: 800;
}

.asset-message,
.operator-message {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
</style>