<template>
  <div class="hourmeter">
    <h2>
      Horímetro
    </h2>
    <hr />
    <div v-if="release" class="release">
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-image">
            <canvas id="releasePhotoTaken" class="canvas-photo" ref="canvas" @click="openModal"></canvas>
            <GDialog v-model="showCamera" max-width="300">
              <CameraComponent @closeCamera="showCamera = false" @takePhoto="takePhoto" v-if="showCamera" />
            </GDialog>
            <div class="hourmeter-image-text">
              <span>Aponte para o horímetro e tire uma foto</span><br />
              <button @click="showCamera = true" class="action-button">
                Tirar foto
              </button>
            </div>
          </div>
          <GDialog v-model="showModal" max-width="500" :overlay-class="'modal-overlay'" :content-class="'customDialog'">
            <div class="modal-header">
              <BIconXLg color="#000" @click="closeModal" style="font-size: 2rem" />
            </div>
            <div class="modal-content">
              <img :src="modalImage" class="hourmeter-image-focused">
            </div>
          </GDialog>
        </div>
      </div>
      <br />
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-info">
            <span>Insira as informações do horímetro</span><br />
            <div class="split_pickers">
              <input placeholder="00" type="text" v-maska="'####'" v-model="release_time_hours"
                class="duration_picker_split" />
              <b>:</b>
              <input v-if="asset.hourmeter_type == 'minutes'" placeholder="00" type="text"
                v-maska="{ mask: 'F#', tokens: { 'F': { pattern: /[0-5]/ }, } }" v-model="release_time_minutes"
                class="duration_picker_split" />
              <input v-if="asset.hourmeter_type == 'decimal'" placeholder="0" type="text"
                v-maska="{ mask: 'F', tokens: { 'F': { pattern: /[0-9]/ }, } }" v-model="release_time_minutes"
                class="duration_picker_split" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-info">
            <span>{{ username }}, você confirma liberar o bem em perfeito estado e com
              {{ release_time_hours }}:{{ release_time_minutes }}h de uso? </span><br />
            <li><input v-model="release_sign" id="confirmar" type="checkbox" /> <label for="confirmar">CONFIRMAR</label>
            </li>
          </div>
        </div>
      </div>
      <br />
      <button
        :disabled="!release_sign || !release_time_hours || !release_time_minutes || !hasPicture || sendingHourmeterData"
        @click="sendReleaseHourmeter" class="confirm-action-button">
        {{ sendingHourmeterData ? 'Enviando...' : 'Confirmar' }}
      </button>
      <br />
    </div>
    <div v-if="!release" class="receipt">
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-image">
            <canvas id="receiptPhotoTaken" class="canvas-photo" ref="canvas" @click="openModal"></canvas>
            <GDialog v-model="showCamera" max-width="300">
              <CameraComponent @closeCamera="showCamera = false" @takePhoto="takePhoto" v-if="showCamera" />
            </GDialog>
            <div class="hourmeter-image-text">
              <span>Aponte para o horímetro e tire uma foto</span><br />
              <button @click="showCamera = true" class="action-button">
                Tirar foto
              </button>
            </div>
          </div>

          <GDialog v-model="showModal" max-width="500" :overlay-class="'modal-overlay'" :content-class="'customDialog'">
            <div class="modal-header">
              <BIconXLg color="#000" @click="closeModal" style="font-size: 2rem" />
            </div>
            <div class="modal-content">
              <img :src="modalImage" class="hourmeter-image-focused">
            </div>
          </GDialog>

        </div>
      </div>
      <br />
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-info">
            <span>Insira as informações do horímetro</span><br />
            <div class="times">
              <div class="releasetimes">
                <label for="release-time">
                  Horímetro saída
                </label>
                <input type="text" id="release-time" v-model="release_time_formated" disabled class="duration_picker" />
              </div>
              <div class="receipttimes">
                <label for="receipt-time">
                  Horímetro entrada
                </label>
                <div class="split_pickers">
                  <input placeholder="00" type="text" v-maska="'####'" v-model="receipt_time_hours"
                    v-bind:class="negative_difference ? 'form-control is-invalid duration_picker_split' : 'duration_picker_split'" />
                  <b>:</b>
                  <input v-if="asset.hourmeter_type == 'minutes'" placeholder="00" type="text"
                    v-maska="{ mask: 'F#', tokens: { 'F': { pattern: /[0-5]/ }, } }" v-model="receipt_time_minutes"
                    v-bind:class="negative_difference ? 'form-control is-invalid duration_picker_split' : 'duration_picker_split'" />
                  <input v-if="asset.hourmeter_type == 'decimal'" placeholder="0" type="text"
                    v-maska="{ mask: 'F', tokens: { 'F': { pattern: /[0-9]/ }, } }" v-model="receipt_time_minutes"
                    v-bind:class="negative_difference ? 'form-control is-invalid duration_picker_split' : 'duration_picker_split'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="card hourmeter-card card-image">
        <div class="card-body text-center">
          <div class="hourmeter-info">
            <span>{{ username }}, você confirma que entrega o bem com {{ this.difference_time }}h de uso?
            </span><br />
            <li><input v-model="receipt_sign" id="confirmar" type="checkbox" /> <label for="confirmar">CONFIRMAR</label>
            </li>
          </div>
        </div>
      </div>
      <br />
      <button :disabled="!receipt_sign || negative_difference || !hasPicture || sendingHourmeterData"
        @click="sendReceiptHourmeter" class="confirm-action-button">
        {{ sendingHourmeterData ? 'Enviando...' : 'Confirmar' }}
      </button>
      <br />
    </div>
  </div>
</template>
<script>
import CameraComponent from "./Camera.vue";
import { apiService } from "../../../util/api.service";
import { BIconXLg } from "bootstrap-icons-vue";

export default {
  name: "OperatorReserveList",
  components: {
    BIconXLg,
    CameraComponent,
  },
  computed: {
    username: function () {
      return localStorage.getItem('username') != undefined ? localStorage.getItem('username') : "Fulano";
    },
  },
  props: {
    release: { required: false, type: Boolean, default: true },
    reserve_id: { required: true, type: Number, default: undefined },
    asset: { required: true, type: Object, default: undefined },
  },
  watch: {
    receipt_time_hours(newTime, oldTime) {
      if (newTime != oldTime) {
        this.difference_time = this.difference();
      }
    },
    receipt_time_minutes(newTime, oldTime) {
      if (newTime != oldTime) {
        this.difference_time = this.difference();
      }
    },
  },
  data() {
    return {
      showCamera: false,
      release_time: "20:20",
      release_time_hours: "",
      release_time_minutes: "",
      release_sign: false,
      receipt_time: "",
      receipt_time_hours: "",
      receipt_time_minutes: "",
      receipt_sign: false,
      negative_difference: true,
      hasPicture: false,
      sendingHourmeterData: false,
      difference_time: "00:00",
      release_time_formated: "",
      showModal: false,
    }
  },
  mounted() {
    this.hasPicture = false;
    if (!this.release) {
      this.getHourmeterData();
    }
  },
  methods: {
    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    },
    difference() {
      if (this.receipt_time_hours && this.receipt_time_minutes) {
        let release = this.release_time.split(":");
        let hours_to_seconds_release = parseInt(release[0]) * 3600;
        let minutes_to_seconds_release = parseInt(release[1]) * 60;
        let hours_to_seconds_receipt = parseInt(this.receipt_time_hours) * 3600;
        let minutes_to_seconds_receipt = parseInt(this.receipt_time_minutes) * 60;

        if (this.asset.hourmeter_type == "decimal") {
          minutes_to_seconds_release = minutes_to_seconds_release * 6;
          minutes_to_seconds_receipt = minutes_to_seconds_receipt * 6;
        }

        let total_seconds = ((hours_to_seconds_receipt + minutes_to_seconds_receipt) - (hours_to_seconds_release + minutes_to_seconds_release));
        this.negative_difference = false;
        let minutes = 0;
        let hours = 0;
        if (total_seconds < 0) {
          this.negative_difference = true;
          hours = Math.ceil(total_seconds / 3600);
          minutes = Math.abs(Math.ceil((total_seconds - (hours * 3600)) / 60));
        } else {
          hours = Math.floor(total_seconds / 3600);
          minutes = Math.floor((total_seconds - (hours * 3600)) / 60);
        }
        let elapsed_time = this.zeroPad(hours, 2) + ":" + this.zeroPad(minutes, 2)
        if (this.negative_difference && elapsed_time != "00:00" && !elapsed_time.includes("-")) {
          return "-" + elapsed_time;
        } else {
          return elapsed_time;
        }
      }
      return "00:00"
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    takePhoto(image, closeCameraStream) {
      const canvas = this.$refs.canvas;
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      this.showCamera = false;
      this.hasPicture = true;
      closeCameraStream();
    },
    openModal() {
      this.showModal = true;
      const canvas = this.$refs.canvas;
      this.modalImage = canvas.toDataURL("image/jpeg"); // Replace with the actual image path
    },
    closeModal() {
      this.showModal = false;
      this.modalImage = null;
    },
    getHourmeterData() {
      apiService.getHourmeterData(this.reserve_id, (data) => {
        this.release_time = data.data["release_hours"];
        if (this.asset.hourmeter_type == "decimal") {
          let time_splited = this.release_time.split(":");
          this.release_time_formated = time_splited[0] + ":" + parseInt(time_splited[1]);
        } else {
          this.release_time_formated = this.release_time;
        }
      });
    },
    sendReleaseHourmeter() {
      this.sendingHourmeterData = true;
      const canvas = document.getElementById("releasePhotoTaken");
      this.release_time = this.zeroPad(this.release_time_hours, 2) + ":" + this.zeroPad(this.release_time_minutes, 2);
      let data = {
        operation: "RELEASE",
        operator_term_signed: this.release_sign,
        hours: this.release_time,
        image: this.dataURLtoFile(canvas.toDataURL("image/jpeg"), "release_file")
      };
      apiService.postHourmeterData(this.reserve_id, data, (data) => {
        if (data.status == 200) {
          if (this.asset.hourmeter_type == "decimal") {
            let time_splited = this.release_time.split(":");
            this.release_time_formated = time_splited[0] + ":" + parseInt(time_splited[1]);
          } else {
            this.release_time_formated = this.release_time;
          }
          this.$emit("hourmeterSuccess", this.release_time_formated);
        }
      }, () => this.sendingHourmeterData = false);
    },
    sendReceiptHourmeter() {
      this.sendingHourmeterData = true;
      const canvas = document.getElementById("receiptPhotoTaken");
      this.receipt_time = this.zeroPad(this.receipt_time_hours, 2) + ":" + this.zeroPad(this.receipt_time_minutes, 2);
      let data = {
        operation: "RECEIPT",
        operator_term_signed: this.receipt_sign,
        hours: this.receipt_time,
        image: this.dataURLtoFile(canvas.toDataURL("image/jpeg"), "release_file")
      };
      apiService.postHourmeterData(this.reserve_id, data, (data) => {
        if (data.status == 200) {
          this.$emit("hourmeterSuccess", this.difference());
        }
      }, () => this.sendingHourmeterData = false);
    }
  }
}
</script>

<style>
.hourmeter {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.hourmeter>h2 {
  text-align: center;
}

.hourmeter-card {
  min-height: 150px;
  height: auto;
}

.hourmeter-image {
  display: flex;
}

.hourmeter-image-text {
  margin: auto auto auto 0;
  font-weight: 700;
}

.hourmeter-image-focused {
  width: 100%;
}

.action-button,
.confirm-action-button {
  font-weight: bold;
  font-size: 12px;
  margin: 12px 2px;
  text-align: center;
  width: 70%;
  border-style: none;
  height: 40px;
  color: white;
  background: black 0% 0% no-repeat padding-box;
}

.confirm-action-button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.confirm-action-button:disabled {
  background: rgb(171 171 171) 0% 0% no-repeat padding-box
}

.hourmeter-info {
  font-weight: 700;
  margin-top: 16px;
  display: grid;
}

.hourmeter-info>input {
  margin-left: auto;
  margin-right: auto;
}

li {
  list-style-type: none;
}

.times {
  display: flex;
  align-items: center;
  justify-content: center;
}

.times>div {
  display: flex;
  flex-direction: column;
}

.releasetimes {
  margin-right: 8px;
}

.receipttimes {
  margin-left: 8px;
}

.customDialog {
  background: rgba(0, 0, 0, 0.3) !important;
  height: 88.3%;
}

.customDialog .modal-header,
.customDialog .modal-content {
  background: transparent;
  background-color: transparent;
  border: 0;
}

.customDialog .modal-content {
  margin-top: 45%;
  padding: 10px;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>

<style scoped>
hr {
  position: relative;
  background: #4E4E4E 0% 0% no-repeat padding-box;
  width: 48px;
  height: 6px !important;
  opacity: 1;
  border-radius: 10px;
  margin: auto auto 20px auto;
}

input[type="text"] {
  border: none;
  color: #2a2c2d;
  font-size: 16px;
  font-family: helvetica;
  background-color: white;
  border-radius: 10px;
  width: 88px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
}

.split_pickers {
  display: flex;
}

.duration_picker_split:first-child {
  margin-right: unset !important;
}

.duration_picker_split:last-child {
  margin-left: unset !important;
}

.duration_picker_split {
  width: 60px !important;
  height: 26px !important;
  padding-left: 0px !important;
  text-align: center;
}

.modal-header svg {
  text-align: end;
  margin-left: auto;
  color: #fff;
  font-weight: bold;
}

.hourmeter .hourmeter-image .canvas-photo {
  width: 50%;
  height: 120px;
  float: left;
  object-fit: contain;
  -o-object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
}
</style>