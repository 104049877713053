<template>
  <div class="login vh-100 vw-100">
    <HeaderLogo />
    <div class="text-center mt-4" v-if="success">
      <p class="success">{{ message_success }}</p>
      <button
        type="submit"
        class="btn rounded-button"
        v-if="success"
        @click="backToLogin"
      >
        Voltar
      </button>
    </div>

    <form
      v-else
      method="POST"
      class="text-center mt-4"
      @submit.prevent="forgotPassword"
    >
      <p class="error" v-if="error">{{ message_error }}</p>
      <p>Digite seu e-mail para recuperar senha</p>
      <input
        type="text"
        name="email"
        v-model="input.email"
        placeholder="E-MAIL"
        class="rounded-input text-center"
        required
      />
      <br />
      <button type="submit" class="btn rounded-button">Enviar</button>
    </form>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import { apiService } from "../../util/api.service";

export default {
  name: "ForgotPassword",
  components: {
    HeaderLogo,
  },
  data() {
    return {
      input: {
        email: "",
      },
      error: false,
      success: false,
      message_error: "Este e-mail não foi encontrado.",
      message_success: "Nós lhe enviamos as instruções para configurar sua nova senha por e-mail. Você deve receber o e-mail em breve!",
    };
  },
  methods: {
    forgotPassword() {
      if (this.input.email != "" && this.input.password != "") {
        let loader = this.$loading.show({
          container: null,
          canCancel: false,
        });
        this.error = false;
        apiService.postForgotPassword(
          this.input.email,
          () => {
            loader.hide();
            this.error = false;
            this.success = true;
          },
          (error) => {
            loader.hide();
            console.log(error);
            this.error = true;
          }
        );
      } else {
        this.message_error = "Necessário email e senha";
        this.error = true;
      }
    },
    backToLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*,
/*resetar o estilo da página*/
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

p {
  text-align: center;
  font: normal normal bold 14px/20px Helvetica Neue;
  letter-spacing: 0px;
  color: #3b3b3b;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.login {
  background: url("@/assets/img/forgotpassword_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error {
  color: red;
  font: normal normal bold 12px/14px Helvetica Neue;
}

.login input {
  text-align: left;
  font: normal normal bold 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  color: #000000cc;
  padding: 10px;
}

.login button {
  background: transparent linear-gradient(0deg, #000000 0%, #363636 100%) 0% 0%
    no-repeat padding-box;
  text-align: center;
  font: normal normal bold 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  width: 100px;
}

.forgot-password {
  text-align: left;
  font: normal normal bold 12px/14px Helvetica Neue;
  letter-spacing: 0px;
  color: #3b3b3b;
  text-transform: uppercase;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>
