<template>
  <div class="operatorreservecomponent">
    <VueLoading v-model="sendingReview" :can-cancel="false" :isFullPage="true"></VueLoading>
    <GDialog v-model="dialogState" max-width="500">
      <div class="confirmation-modal-wrapper" @close="closeDialog()">

        <GDialog v-model="dialogReleaseWarningState" max-width="500" persistent>
          <div class="confirmation-modal-content">
            <div class="confirmation-modal-title release-warning-title">Atenção!</div>
            <p class="release-warning-body">Você está prestes a liberar o bem de forma antecipada. Tem certeza de que
              deseja prosseguir com essa ação?</p>
            <button class="btn btn-lg button-black confirmation-modal-button" @click="closeDialogReleaseWarning()">
              Confirmar
            </button>
          </div>
        </GDialog>

        <div
          v-if="this.dialogState && this.allowRelease && !this.readQRCode && !this.showReview && !this.reviewSent && !this.hourmeter && !this.readVerificationCode"
          class="confirmation-modal-content">
          <div class="confirmation-modal-close-icon">
            <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
          </div>
          <div class="confirmation-modal-title">{{ title }}</div>
          <div class="profile-image">
            <img :src="profilePhoto" alt="Avatar" />
          </div>
          <div class="card">
            <div class="card-body">
              <h3 class="card-title-confirmation-modal">{{ subtitle }}</h3>
              <p>
                <b>Reservada por:</b> {{ data.client_name }}<br />
                <b>CPF:</b> {{ formatCPF(data.client_cpf) }}<br />
                <b>Data de retorno:</b> {{ formatDate(data.receipt_date)
                }}<br />
                <b>Ativo:</b> {{ data.asset.name }}
              </p>
            </div>
          </div>
          <button class="btn btn-lg button-black confirmation-modal-button" @click="readQRCodeAction()">
            Confirmar
          </button>
        </div>

        <div v-if="this.readQRCode" class="confirmation-modal-content">
          <div class="confirmation-modal-close-icon">
            <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
          </div>
          <OperatorCameraScanner :code="this.data.asset.qrcode_identifier" @confirmQRCode="confirmQRCode" />
        </div>

        <div v-if="this.showReview" class="confirmation-modal-content">
          <div class="confirmation-modal-close-icon">
            <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
          </div>
          <div class="confirmation-modal-title"> Avaliação do cliente </div>
          <div class="card">
            <div class="card-body">
              <h3 class="card-title-confirmation-modal">Operador, marque no quadro para confirmar as perguntas</h3>
              <input type="radio" v-model="delay_option" id="delay-ontime" value="ontime" name="delay_options">
              <label for="delay-ontime">Entregou na hora certa</label>
              <br />
              <input type="radio" v-model="delay_option" id="delay-delayed" value="delayed" name="delay_options">
              <label for="delay-delayed">Entregou atrasado</label>
              <br />
              <br />
              <input type="checkbox" v-model="spoiled" id="spoiled-others" name="spoiled_others">
              <label for="spoiled-others">Prejudicou outro cotista</label>
            </div>
          </div>
          <br />
          <br />
          <div class="card">
            <div class="card-body">
              <input type="radio" v-model="condition_option" id="condition-perfect" value="condition_perfect"
                name="condition">
              <label for="condition-perfect">Entregou em perfeito estado</label>
              <br />
              <input type="radio" v-model="condition_option" id="condition-good" value="condition_good" name="condition">
              <label for="condition-good">Entregou com pequena avaria</label>
              <br />
              <input type="radio" v-model="condition_option" id="condition-average" value="condition_average"
                name="condition">
              <label for="condition-average">Entregou com média avaria</label>
              <br />
              <input type="radio" v-model="condition_option" id="condition-bad" value="condition_bad" name="condition">
              <label for="condition-bad">Entregou com perda total</label>
              <br />
              <br />
              <input type="checkbox" v-model="stolen" id="stolen" name="stolen">
              <label for="stolen">Informou que o bem foi roubado</label>
            </div>
          </div>
          <button class="action-button" @click="sendReview()">
            Confirmar
          </button>
        </div>


        <div v-if="this.reviewSent" class="confirmation-modal-content">
          <div class="confirmation-modal-close-icon">
            <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
          </div>
          <div class="confirmation-modal-title"> {{ formatDate(new Date()) }} - Sua avaliação foi finalizada com sucesso
          </div>
          <button class="action-button" @click="closeDialog()">
            Ok
          </button>
        </div>

        <div v-if="this.hourmeter" class="confirmation-modal-content">
          <OperatorHourmeter @hourmeterSuccess="handleHourmeterSuccess" :reserve_id="data.id" :release="hourmeterRelease"
            :asset="data.asset"></OperatorHourmeter>
        </div>

        <div
          v-if="!this.allowRelease && !this.readQRCode && !this.showReview && !this.reviewSent && !this.readVerificationCode && !this.hourmeter"
          class="confirmation-modal-content">
          <div class="confirmation-modal-close-icon">
            <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
          </div>
          <div class="confirmation-modal-title">
            Não é possível liberar uma reserva antes do dia em que foi
            reservada!
          </div>
          <button class="btn btn-lg button-black confirmation-modal-button" @click="closeDialog()">
            OK
          </button>
        </div>
      </div>
      <div v-if="this.readVerificationCode" class="confirmation-modal-content">
        <div class="confirmation-modal-close-icon">
          <BIconXLg color="#E0A100" @click="closeDialog()" style="font-size: 2rem" />
        </div>
        <div class="confirmation-modal-title"> Validação </div>
        <div class="card">
          <div class="card-body confirmation-code-body">
            {{ data.client_name }}<br>
            <template v-if="this.data.status != 'APROVADA'">
              confirma que utilizou o<br>
              {{ data.asset.name }} por<br>
            </template>
            <template v-else>
              confirma que o horímetro do<br>
              {{ data.asset.name }} está em<br>
            </template>
            {{ horas_usadas }} horas?<br><br>
            Insira o código de validação.<br>
            <div class="confirmation-code-inputs">
              <input type="text" style="text-transform:uppercase" id="verification-code-1"
                v-on:keyup="handleVerificationCode($event)" maxlength="1">
              <input type="text" style="text-transform:uppercase" id="verification-code-2"
                v-on:keyup="handleVerificationCode($event)" maxlength="1">
              <input type="text" style="text-transform:uppercase" id="verification-code-3"
                v-on:keyup="handleVerificationCode($event)" maxlength="1">
              <input type="text" style="text-transform:uppercase" id="verification-code-4"
                v-on:keyup="handleVerificationCode($event)" maxlength="1">
            </div>
            <span id="verification-code-error" class="error"></span>
          </div>
        </div>
        <button class="action-button" @click="confirmVerificationCode()">
          Confirmar
        </button>
        <p class="text-center" style="font-size: 11px;">O código de validação foi enviado para o email {{
          data.client_email }}</p>
        <div class="button-back text-center">
          <button @click="back" class="btn btn-circle button-black rounded-button">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" role="img" focusable="false">
              <path fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </GDialog>

    <div class="card card-image">
      <div class="card-body text-center">
        <div class="asset-description">
          <img class="img" :src="getImage()" />
          <div class="asset-info">
            <p>
              <span id="asset-name">{{ data.asset.name }}</span><br />
              <span id="reserve-client"><strong>Reservada por:</strong> {{ data.client_name }}</span><br />
              <span id="reserve-date"><strong>Data:</strong> {{ formatDate(getDate()) }}</span>
            </p>
          </div>
        </div>
        <div>
          <button class="action-button" @click="modalAction()">
            {{ action }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconXLg } from "bootstrap-icons-vue";
import dayjs from "dayjs";
import { apiService } from "../../../util/api.service";
import { ref } from "vue";
import OperatorCameraScanner from "./OperatorCameraScanner.vue";
import OperatorHourmeter from "./OperatorHourmeter.vue";
import VueLoading from 'vue-loading-overlay';


export default {
  name: "OperatorReserveComponent",
  components: {
    BIconXLg,
    OperatorCameraScanner,
    OperatorHourmeter,
    VueLoading,
  },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      delay_option: "",
      condition_option: "",
      spoiled: false,
      stolen: false,
      horas_usadas: "",
    }
  },
  computed: {
    action() {
      if (this.data.status == "EM POSSE") {
        return "Receber";
      }
      return "Liberar";
    },
    profilePhoto() {
      if (this.data.client_image) {
        return apiService.baseURL + this.data.client_image;
      }
      return require("../../../assets/img/img_avatar.png");
    },
  },
  methods: {
    modalAction() {
      this.allowRelease = this.data.releasable;
      this.dialogState = true;
      this.dialogReleaseWarningState = this.data.releasable_within_next_day
    },
    getDate() {
      return this.data.status == "EM POSSE"
        ? this.data.receipt_date
        : this.data.release_date;
    },
    getImage() {
      if (this.data.asset.photo) {
        return apiService.baseURL + this.data.asset.photo;
      }
      return require("@/assets/img/jetski.png");
    },
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    formatCPF(cpf) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    readQRCodeAction() {
      this.readQRCode = true;
    },
    confirmQRCode() {
      this.readQRCode = false;
      this.dialogState = true;
      this.hourmeter = true;
      this.hourmeterRelease = this.data.status != "EM POSSE";
    },
    handleHourmeterSuccess(used_hours) {
      this.readVerificationCode = true;
      this.readQRCode = false;
      this.allowRelease = false;
      this.hourmeter = false;
      this.horas_usadas = used_hours;
    },
    async confirmVerificationCode() {
      let confirmationCode = document.getElementById('verification-code-1').value + document.getElementById('verification-code-2').value + document.getElementById('verification-code-3').value + document.getElementById('verification-code-4').value
      document.getElementById("verification-code-error").innerHTML = ""
      await apiService.confirmCode(this.data.id, confirmationCode, () => {
        let toStatus = "finished";
        if (this.data.status == "Aprovada" || this.data.status == "APROVADA") {
          toStatus = "inpossession";
        }
        apiService.getChangeReserveStatus(this.data.id, toStatus, () => {
          if (toStatus == "inpossession") {
            this.$emit("updateReserves");
            this.dialogState = false;
            this.hourmeter = false;
          } else {
            this.readQRCode = false;
            this.showReview = true;
            this.hourmeter = false;
            this.readVerificationCode = false;
          }
        });
      },
        () => {
          document.getElementById("verification-code-error").innerHTML = "Código de validação inválido."
        }
      );
    },
    sendReview() {
      this.sendingReview = true;
      let data = {
        reserve_id: this.data.id,
        arrived_on_time: this.delay_option == "ontime" ? true : false,
        arrived_with_delay: this.delay_option == "delayed" ? true : false,
        spoiled_other: this.spoiled,
        condition_perfect: this.condition_option == "condition_perfect" ? true : false,
        condition_good: this.condition_option == "condition_good" ? true : false,
        condition_average: this.condition_option == "condition_average" ? true : false,
        condition_bad: this.condition_option == "condition_bad" ? true : false,
        stolen: this.stolen
      }
      this.reviewSent = apiService.postReview(data, (response) => {
        if (response.success) {
          return true;
        }
        return false;
      }, () => { return false; });
      this.sendingReview = false;
      this.showReview = false;
    },
    closeDialog() {
      this.dialogState = false;
      this.dialogReleaseWarningState = false;
      this.reviewSent = false;
      this.sendingReview = false;
      this.showReview = false;
      this.readQRCode = false;
      this.allowRelease = true;
      this.hourmeter = false;
      this.hourmeterRelease = true;
      this.$emit("updateReserves");
    },
    closeDialogReleaseWarning() {
      this.dialogReleaseWarningState = false;
    },
    handleVerificationCode(event) {
      // Focus next or previous input based on id number and if its value is empty
      let input_id = event.target.id
      let current_id_number = parseInt(input_id[input_id.length - 1])
      if (document.getElementById(input_id).value.length != 0 && input_id != "verification-code-4") {
        document.getElementById('verification-code-' + (current_id_number + 1)).focus()
      }
      if (document.getElementById(input_id).value.length == 0) {
        if (input_id != "verification-code-1") {
          document.getElementById('verification-code-' + (current_id_number - 1)).focus()
        } else {
          document.getElementById('verification-code-1').value = ""
        }
      }
    },
    back() {
      this.readVerificationCode = false;
      this.readQRCode = false;
      this.dialogState = true;
      this.hourmeter = true;
      this.hourmeterRelease = this.data.status != "EM POSSE";
    }
  },
  setup(props) {
    const dialogState = ref(false);
    const dialogReleaseWarningState = ref(false);
    const allowRelease = ref(true);
    const readQRCode = ref(false);
    const readVerificationCode = ref(false);
    const showReview = ref(false);
    const sendingReview = ref(false);
    const reviewSent = ref(false);
    const hourmeter = ref(false);
    const hourmeterRelease = ref(true);

    let isForRelease =
      props.data.status == "APROVADA" || props.data.status == "Aprovada";
    const title = isForRelease
      ? "Confirmação da liberação"
      : "Confirmação do recebimento";
    const subtitle = isForRelease
      ? "Dados da liberação"
      : "Dados do recebimento";
    return {
      dialogReleaseWarningState,
      dialogState,
      allowRelease,
      title,
      subtitle,
      readQRCode,
      readVerificationCode,
      showReview,
      sendingReview,
      reviewSent,
      hourmeter,
      hourmeterRelease,
    };
  },
};
</script>

<style scoped>
/* START CONFIRMATION MODAL CSS */
.confirmation-modal-wrapper {
  background: #e5e5e5;
  text-align: center;
}

.confirmation-modal-content {
  padding: 20px;
}

.confirmation-modal-content img {
  margin-bottom: -26px;
}

.confirmation-modal-content .card {
  text-align: left;
}

.confirmation-modal-content p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
}

.confirmation-modal-close-icon {
  text-align: end;
}

.confirmation-modal-title {
  font-family: "Kanit";
  font-style: normal;
  font-size: 30px;
  font-weight: 700;
  color: black;
  text-align: center;
}

.release-warning-title {
  color: #fac829;
}

.release-warning-body {
  margin-top: 16px;
  text-align: center;
  font-weight: 700;
  color: black;
}

.card-title-confirmation-modal {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  color: #e0a100;
  text-align: center;
  margin-bottom: 24px;
}

.confirmation-modal-button {
  width: 100%;
  margin-top: 30px;
}

input[type="radio"],
input[type="checkbox"] {
  transform: scale(1.5);
  margin-top: 6px;
  float: right;
  height: 20px;
}

label {
  font-size: 18px;
  font-weight: 900;
  display: inline;
}

/* END CONFIRMATION MODAL CSS */

.operatorreservecomponent {
  margin: 10px auto 0px auto;
  max-width: 344px;
}

.profile-image {
  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 8px;
}

.profile-image img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
}

.card {
  border-width: 0px;
}

.card-image {
  max-height: 180px;
  z-index: 1;
  border-radius: 0px;
  background-color: whitesmoke;
  overflow: hidden;
}

.card-image .img {
  max-height: 120px;
  object-fit: scale-down;
  display: block;
  width: 124px !important;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.asset-info {
  margin-left: 24px;
  text-align: left;
}

.asset-info>p {
  margin-bottom: 0;
}

.asset-description {
  display: flex;
}

.asset-description>span {
  font-size: 14px;
  max-height: auto;
}

#asset-name {
  color: #d5b300;
  font-weight: bolder;
}

#reserve-client {
  position: relative;
  font-size: 12px;
  text-align: center;
}

#reserve-date {
  position: relative;
  font-size: 12px;
  text-align: center;
}

.action-button {
  font-weight: bold;
  font-size: 12px;
  margin: 12px 2px;
  text-align: center;
  width: 100%;
  border-style: none;
  height: 30px;
  color: white;
  background: black 0% 0% no-repeat padding-box;
}

.confirmation-code-body {
  text-align: center;
}

.confirmation-code-inputs>input {
  width: 30px;
  height: 40px;
  margin: 0px 4px;
  text-align: center;
  border-radius: 5px;
}

.error {
  color: red;
  font: normal normal bold 12px/14px Helvetica Neue;
}

.button-black {
  height: auto;
}
</style>
