<template>
    <div class="card card-image">
        <div class="card-body text-center">
            <div class="quota-description">
                <img class="img" :src="getImage()" />
                <div class="quota-info">
                    <div class="quotas-info">
                        <span id="quota-name">{{ data.name }}</span><br />
                        <span id="quota-code" class="quota-code">Código do bem: {{ data.code }}</span><br />
                        <div class="dex-logo"><img src="@/assets/img/dex.png" cylass="dex-logo-img"><span class="quota-balance-text"> {{ data.dex }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { apiService } from "../../../util/api.service";

export default {
    name: "QuotaBalance",
    props: {
        data: { type: Object },
    },
    methods: {
        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format("DD/MM/YYYY");
        },
        getImage() {
            if (this.data.photo) {
                return apiService.baseURL + "/" + this.data.photo;
            }
            return require("@/assets/img/jetski.png");
        },
    }
};

</script>

<style scoped>
.dex-logo {
    margin-top: 4px;
}
.dex-logo img {
    width: 28px;
    height: 28px;
    margin: 0px 10px 0px 0px;
    vertical-align: bottom;
}
.quota-balance-text {
    font-size: 16px;
}
.card {
    font-family: "Kanit";
    font-style: normal;
    border-width: 0px;
    font-size: 18px;
    letter-spacing: 1px;
}

.card-image {
    margin:0px auto;
    max-height: 150px;
    max-width: 500px;
    border-radius: 10px;
    width: 90%;
    z-index: 1;
    background-color: whitesmoke;
    overflow: hidden;
}

.text-left {
    text-align: left;
    font-size: 0.85rem;
    padding-top: 10px;
}

.card-image .img {
    max-height: 120px;
    object-fit: scale-down;
    display: block;
    width: 124px !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.quota-description {
    display: flex;
}
.quota-info {
    margin-left: 20px;
    text-align: left;
}
.quotas-info span:not(:first-child) {
    display: inline-block;
    margin-top: 12px;
    font-weight: 500;
}
#quota-name {
    font-weight: bold;
}
#quota-code{
    font-weight: 500;
}
.dex-round-logo {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding-top: 7px;
    margin-right: 12px;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    background-color: black;
    color: white;
    border-radius: 50%;
}
#quota-dex {
    position:relative;
    top: 2px;
}

@media only screen and (max-width: 420px) {
    .card-image {
        max-height: 172px;;
    }
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
