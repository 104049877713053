<template>
  <div class="main-container vh-100 vw-100">
    <div class="login-container">
      <div class="header-container">
        <img class="registration-icon" src="@/assets/img/efex-icon.png"/>
      </div>
      <form method="POST" class="text-center mt-4 form-container" @submit.prevent="register">
        <p class="error" v-if="error">{{ registration_error }}</p>
        <h1 class="input-title">Entrar</h1>
        <input
          type="email"
          name="email"
          v-model="input.email"
          placeholder="E-mail"
          class="rounded-input"
          required
        />
        <input
          type="text"
          name="name"
          v-model="input.name"
          placeholder="Nome"
          class="rounded-input"
          required
        />
        <button type="submit" class="btn rounded-button">Entrar</button>
      </form>
    </div>
  </div>
</template>

<script>
import { apiService } from "../../util/api.service";

export default {
  name: "RegistrationPage",
  data() {
    return {
      input: {
        name: "",
        email: "",
      },
      error: false,
      registration_error: "*Erro no registro",
    };
  },
  methods: {
    register() {
      if (this.input.email !== "" && this.input.name !== "") {
        let loader = this.$loading.show({
          container: null,
          canCancel: false,
        });
        this.error = false;
        apiService.leadRegister(
          this.input.name,
          this.input.email,
          () => {
            loader.hide();
            this.error = false;
            localStorage.setItem("email", this.input.email);
            this.$router.push('/simulado/escolha');
          },
          (error) => {
            loader.hide();
            localStorage.clear();
            this.registration_error = error.response.data.message;
            this.error = true;
          }
        );
      } else {
        this.registration_error = "Necessário nome e email";
        localStorage.clear();
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.registration-icon {
  padding-top: 40px;
  width: 240px;
}

.input-title {
  color: #FFF;
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 8px;
}

.main-container {
  background: url("@/assets/img/login_background_simulator.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 342px;
  height: 513px;
  flex-shrink: 0;
  border-radius: 28px;
  background: rgba(90, 101, 129, 0.60);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container input {
  width: 100%;
  box-sizing: border-box;
}
.error {
  color: red;
  font: normal normal bold 12px/14px Helvetica Neue;
}

.login-container input {
  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #000000cc;
  font-size: 16px;
  letter-spacing: 0px;
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  border: 1px solid #DEE2E6;
  width: 286px;
  height: 38px;
}

.login-container button {
  display: flex;
  width: 286px;
  padding: 10px 16px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #FFFF;
  border-radius: 6px;
  background: #0050B7;
}

.header-container {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.5px;
  color: #FFF;
  margin-top: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (min-width: 768px) {
  .main-container {
    background: url("@/assets/img/background_full_size.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: contrast(1.1) brightness(1.05);
  }
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Inter&display=swap');
</style>
