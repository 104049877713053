<template>
  <div class="navbar">
    <div class="navbar-left">
      <h1 class="navbar-title">EFEX Escola Náutica</h1>
    </div>
    <div class="navbar-right">

      <div class="menu-icon" @click="toggleMenu">
        <img class="navbar-icon" src="@/assets/img/menu-icon.png" alt="Icon"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimulatorHeader",
  methods: {
    toggleMenu() {
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #0050B7;
  height: 56px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-title {
  color:#F8F9FA;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }

.navbar-right {
  display: flex;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
}
</style>