<template>
  <Slide noOverlay right>
    <div class="menu-header" id="page-wrap">
      <div class="profile-image">
        <img :src="profilePhoto" alt="Avatar" />
      </div>
      <div class="profile-info">
        <p><span>Olá, {{username}}!</span></p>
        <p style="text-align: left; font-size:14px"><span>Operador</span></p>
        <p style="text-align: center">
          <router-link to="/changepassword">
            <button class="changepass-button" @click="changePassword">
              Alterar senha
            </button>
          </router-link>
        </p>
      </div>
    </div>
    <div class="menu-body">
      <ul class="menu-items">
        <hr style="width: 100%" />
        <li>
          <router-link to="/operator/reserves">Reservas</router-link>
        </li>
        <hr style="width: 100%" />
        <li>
          <router-link @click="logout()" to="/">Sair</router-link>
        </li>
        <hr style="width: 100%" />
      </ul>
      
      <div class="menu-footer">
        <span>&copy;   2022 - 2022</span>
        <span>Privacy - Terms</span>
      </div>
    </div>
  </Slide>
</template>

<script>
import { Slide } from "vue3-burger-menu";
import { apiService } from "../../../util/api.service";

export default {
  name: "OperatorSideMenu",
  components: {
    Slide,
  },
  computed: {
    username: function() {
      return localStorage.getItem('username') != undefined ? localStorage.getItem('username') : "Usuário";
    },
    profilePhoto: function() {
      // "null" because the api is returning a string null
      if (localStorage.getItem('profilePhoto') != "null" && localStorage.getItem('profilePhoto') != undefined) {
        return apiService.baseURL + localStorage.getItem('profilePhoto');
      }
      return require("../../../assets/img/img_avatar.png");
    }
  },
  methods: {
    logout() {
      apiService.logout(
        () => {
          console.log("Deslogando usuário logado");
          this.$router.push("/");
        },
        () => {
          console.log("Nenhum usuário logado");
          this.$router.push("/");
        });
    },
  },
};
</script>

<style>
.operator-header .bm-menu {
  padding-top: 0px !important;
  background: #4e4e4e 0% 0% no-repeat padding-box;
}

.operator-header .bm-item-list {
  margin-left: 0px !important;
  height: 100%;
  overflow-y: hidden;
}

.operator-header .bm-item-list > * {
  padding: 0px;
}

.operator-header .bm-burger-button {
  right: 36px;
  left: auto;
}

.operator-header .bm-burger-bars {
  background-color: #777777;
  border-radius: 10px;
  height: 5px;
}
</style>

<style scoped>

.menu-header {
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 120px;
  background: #313131 0% 0% no-repeat padding-box;
}

.menu-body {
  background: #313131 0% 0% no-repeat padding-box;
  height: 100%;
}

ul.menu-items {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  width: 100%;
  margin: 12px 0px 0px 0px; /* Remove margins */
}

.menu-items > li > a {
  font-size: 16px;
  color: white;
  text-decoration: none;
  margin-left: 16px;
}

.menu-items > li > a > svg {
  margin-bottom: 4px;
  margin-right: 12px;
}

.menu-footer {
  position: absolute;
  bottom: 0px;
  left: 8px;
  font-size: 12px
}

.menu-footer > span {
  margin-left: 50px;
}

.menu-footer > span:last-child {
  margin-left: 28px;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  margin-top: 60px;
  margin-left: 20px;
}

.profile-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile-info {
  margin-top: 60px;
  font-size: 16px;
  color: white;
}

.profile-info p {
  padding-top: 0px;
  margin-bottom: 0px;
}

.logout-button {
  font-weight: bold;
  font-size: 11px;
  flex: 1 0 21%;
  margin: 0px 36px;
  text-align: center;
  max-width: 120px;
  color: white;
  border-color: #303030;
  border-radius: 4px;
  background: #303030 0% 0% no-repeat padding-box;
}

.logout-button svg {
  margin-right: 4px;
  margin-bottom: 2px;
}

.changepass-button {
  font-weight: bold;
  font-size: 11px;
  flex: 1 0 21%;
  margin: 0px 36px;
  text-align: center;
  max-width: 120px;
  color: white;
  border-color: #303030;
  border-radius: 4px;
  background: #303030 0% 0% no-repeat padding-box;
}
</style>