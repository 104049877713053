import axios from 'axios';
import getEnv from './env'

const baseURL = getEnv("VUE_APP_BACKEND_HOST");
console.log("baseURL = "+baseURL);

const api_v0 = axios.create({
    baseURL: baseURL + "/api",
    headers: "",
});

const api_v1 = axios.create({
    baseURL: baseURL + "/api/v1",
    headers: "",
});

const simulator_api_v1 = axios.create({
    baseURL: baseURL + "/simulator/api/v1",
    headers: "",
});

export const apiService = {
    baseURL,
    login,
    logout,
    postForgotPassword,
    postResetPassword,
    postChangePassword,
    getQuotaList,
    getQuota,
    getQuotaDex,
    getReserveList,
    getReserveDetails,
    postReserveAsset,
    getReservedDays,
    getChangeReserveStatus,
    checkToken,
    refreshToken,
    getOperatorReserves,
    getHourmeterData,
    postHourmeterData,
    getDexTotal,
    checkUserType,
    postReview,
    confirmCode,
    getDexTransactions,
    getClientAccount,
    getLastActiveConfirmationCode,
    leadRegister,
    generateSimulator,
};

const authorizationHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
    }
}

const authorizationHeadersMultipart = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
    }
}

const setStorageData = (data) => {
    localStorage.setItem("token", data.data.token);
    localStorage.setItem("refreshToken", data.data.refresh);
    localStorage.setItem("username", data.data.username);
    localStorage.setItem("profilePhoto", data.data.profile_photo);
    localStorage.setItem("user_type", data.data.user_type)
}

const removeDataFromStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    localStorage.removeItem("profilePhoto");
    localStorage.removeItem("user_type");
}

function login(email, password, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.post("/login", { email: email, password: password })
        .then((data) => {
            setStorageData(data);
            onSuccess(data);
        })
        .catch((error) => {
            removeDataFromStorage();
            onError(error);
        });
}

function checkToken(onResult = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/verify/${localStorage.getItem("token")}`)
        .then((response) => onResult(response.data.status))
        .catch((error) => onError(error));
}

function checkUserType(onResult = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get('/usertype', authorizationHeaders())
        .then((response) => onResult(response))
        .catch((error) => onError(error));
}

function logout(onSuccess = () => console.log("Logout realizado com sucesso"), onError = () => console.log("Erro ao fazer logout")) {
    if (localStorage.getItem("token")) {
        removeDataFromStorage();
        onSuccess();
    } else {
        onError();
    }
}

function postForgotPassword(email, onSuccess = (data) => console.log(data), onError = (error) => console.log(error)) {
    api_v0.post("/password_reset/", { email: email })
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function postResetPassword(password1, password2, token, onSuccess = (data) => console.log(data), onError = (error) => console.log(error)) {
    api_v1.post("/resetpassword/" + token + "/", { password1: password1, password2: password2 })
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function postChangePassword(current_password, password1, password2, onSuccess = (data) => console.log(data), onError = (error) => console.log(error)) {
    api_v1.post("/changepassword/", {current_password: current_password, password1: password1, password2: password2}, authorizationHeaders()) 
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getQuotaList(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get("/quotas/", authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getReserveList(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get("/reserve/", authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}


function getReserveDetails(reserve_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/reserve/${reserve_id}/details/`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}


function getChangeReserveStatus(reserve_id, status, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/reserve/${reserve_id}/${status}/`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getOperatorReserves(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/operator_reserve_list/`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getHourmeterData(reserve_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/hourmeter/${reserve_id}/`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function postHourmeterData(reserve_id, data, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.post(`/hourmeter/${reserve_id}/`, data, authorizationHeadersMultipart())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getDexTotal(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get("/dex/total/", authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

async function postReview(data, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    await api_v1.post('/review/', data=JSON.stringify(data), authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

async function getQuota(quota_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    await api_v1.get(`/quota/${quota_id}`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

async function getQuotaDex(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    await api_v1.get(`/quotas/dex`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

async function getReservedDays(month, year, asset_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    await api_v1.get(`/reserve/days/${month}/${year}/${asset_id}/`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

async function refreshToken(onResult = data => console.log(data), onError = error => console.log(error)) {
    await api_v0.post(`/token/refresh`, {refresh: localStorage.getItem("refreshToken")}, {
        headers: {
            "Content-Type": "application/json",
        }
    })
    .then((response) => {
        setStorageData(response);
        onResult(response.data);
    })
    .catch((error) => onError(error));
}

async function postReserveAsset(date_list, quota_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    await api_v1.post("/reserve_asset/", {date_list: date_list, quota_id: quota_id}, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function confirmCode(reserve_id, confirmation_code, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.post(`confirm-code/${reserve_id}/`, {confirmation_code: confirmation_code}, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getDexTransactions(quota_id, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/quota/transactions/${quota_id}`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getClientAccount(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/account`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function getLastActiveConfirmationCode(onSuccess = data => console.log(data), onError = error => console.log(error)) {
    api_v1.get(`/last-active-confirmation-code`, authorizationHeaders())
        .then((data) => onSuccess(data))
        .catch((error) => onError(error));
}

function leadRegister(name, email, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    simulator_api_v1.post("/create-lead/", { email: email, name: name})
        .then((data) => {
            onSuccess(data);
        })
        .catch((error) => {
            onError(error);
        });
}

function generateSimulator(email, simulator_type, onSuccess = data => console.log(data), onError = error => console.log(error)) {
    simulator_api_v1.post("/generate-simulator/", {email: email, simulator_type: simulator_type})
        .then((data) => {
            onSuccess(data);
        })
        .catch((error) => {
            onError(error)
        });
} 
