<template>
  <div class="operatorReservesPage">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap" rel="stylesheet">
    <OperatorHeader :showSideMenu="true" />
    <div class="tabs">
      <div class="tab tab-liberar" v-bind:class="[liberarTabState ? 'active' : '']" @click="tabActivation">
        <a class="tab-liberar" href="#">Liberar</a>
      </div>
      <div class="tab tab-receber" v-bind:class="[!liberarTabState ? 'active' : '']" @click="tabActivation">
        <a class="tab-receber" href="#">Receber</a>  
      </div>
    </div>
    <div class="filters">
      <input type="text" class="search" v-model="searchTerm" placeholder="Buscar"/>
      <BIconSortUp width=21 height=27 @click="toggleOrdering()" v-if="order" />
      <BIconSortDown width=21 height=27 @click="toggleOrdering()" v-if="!order"/>
    </div>
    <div id="liberar-content" class="content" v-bind:class="[!liberarTabState ? 'hidden' : '']">
      <p
          v-for="res in filterLiberar()"
          :key="res"
      >
        <OperatorReserveComponent @updateReserves="loadOperatorReserves"
          :data="res"
        />
      </p>
    </div>
    <div id="receber-content" class="content" v-bind:class="[liberarTabState ? 'hidden' : '']">
      <p
          v-for="res in filterReceber()"
          :key="res"
      >
        <OperatorReserveComponent @updateReserves="loadOperatorReserves"
          :data="res"
        />
      </p>
    </div>
  </div>
</template>
<script>
import OperatorHeader from "./components/OperatorHeader.vue";
import OperatorReserveComponent from "./components/OperatorReserveComponent.vue";
import dayjs from "dayjs";
import { BIconSortUp, BIconSortDown } from "bootstrap-icons-vue";
import { apiService } from "../../util/api.service";
import { ref } from 'vue';
import "swiper/css";
import "swiper/css/pagination";
import "@/assets/css/global.css";

export default {
  name: "OperatorReserveList",
  components: {
    OperatorHeader,
    OperatorReserveComponent,
    BIconSortUp,
    BIconSortDown,
  },
  data() {
    return {
      liberarTabState: true,
      searchTerm: undefined,
      order: undefined,
    }
  },
  created() {
    if (localStorage.getItem("user_type") != "Operator") {
      this.$router.push("/");
    }
  },
  mounted() {
    this.loadOperatorReserves();
  },
  methods: {
    tabActivation: function (e) {
      if (e.target.classList.contains("tab-liberar") && !this.liberarTabState) {
        this.liberarTabState = true;
      } else if (
        e.target.classList.contains("tab-receber") &&
        this.liberarTabState
      ) {
        this.liberarTabState = false;
      }
    },
    filterLiberar: function() {
      if (this.searchTerm != undefined && this.searchTerm != "") {
        return this.reserves_liberar.filter((reserve) => {
          return reserve.asset.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || reserve.client_name.toLowerCase().includes(this.searchTerm.toLowerCase()) 
        });
      }
      return this.reserves_liberar;
    },
    filterReceber: function() {
      if (this.searchTerm != undefined && this.searchTerm != "") {
        return this.reserves_receber.filter((reserve) => {
          return reserve.asset.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || reserve.client_name.toLowerCase().includes(this.searchTerm.toLowerCase()) 
        });
      }
      return this.reserves_receber;
    },
    toggleOrdering: function() {
      this.order = !this.order;
      var orderValue = this.order ? 1 : -1;
      this.reserves_receber.sort(function(a, b) {
        let date_1 = dayjs(a.reserve_date);
        let date_2 = dayjs(b.reserve_date);
        let result = date_1 > date_2 ? -1 : date_1 < date_2 ? 1 : 0;
        return result * orderValue;
      });
      this.reserves_liberar.sort(function(a, b) {
        let date_1 = dayjs(a.reserve_date);
        let date_2 = dayjs(b.reserve_date);
        let result = date_1 > date_2 ? -1 : date_1 < date_2 ? 1 : 0;
        return result * orderValue;
      });
    },
  },
  setup() {
    var reserves_liberar = ref([]);
    var reserves_receber = ref([]);
    const loadOperatorReserves = async () => {
      await apiService.getOperatorReserves((reserves) => {
        if (reserves.data[0].status == "EM POSSE" || reserves.data[0].status == "Em posse") {
          reserves_receber.value = reserves.data[0].reserves;
          reserves_liberar.value = reserves.data[1].reserves;
        } else {
          reserves_receber.value = reserves.data[1].reserves;
          reserves_liberar.value = reserves.data[0].reserves;

        }
      });
    };
    return {reserves_liberar, reserves_receber, loadOperatorReserves}
  }
};
</script>

<style>
/* ---- TABS ------*/
.tabs {
  display: flex;
}
.tab {
  width: 50%;
  text-align: center;
  background-color: black;
  color: #8f6700;
  font: 20px "Kanit", sans-serif;
  height: 40px;
  margin-bottom: 16px;
}
.active {
  border-bottom: 2px solid #e0a100;
}
.tab > a,
.tab > a:hover {
  text-decoration: none;
  color: #8f6700;
}
.active > a,
.active > a:hover {
  color: #e0a100;
  font-weight: 700;
}
.content {
  display: block;
}
.hidden {
  display: none;
}

/* ----- Filtros ------- */
.filters {
  margin: auto;
  max-width: 344px;
}

.filters > input {
  border: 1px solid #e2e6eb;
  border-radius: 2px;
  background: transparent
    url("data:image/svg+xml,%3Csvg fill='gray' xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 8px center;
  padding-left: 36px;
  width: 296px;
  height: 37px;
  font-size: 16px;
}

.filters > input::placeholder {
  color: #c2c2c2;
  font-size: 16px;
}

.filters > svg {
  margin-left: 20px;
  cursor: pointer;
}
</style>