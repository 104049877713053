<template>
<div class="camera" @click="stopCameraStream">
    <div class="wrapper-camera" @click.stop="">
        <div class="video-container">
        <video class="camera-video" ref="camera" :width="330" :height="300" autoplay playsinline ></video>
      </div>
       <button class="take-photo-button" @click="takePhoto">
        <span>Tirar Foto</span>
      </button>
    </div>
</div>
</template>

<script>

export default {
    name: "CameraComponent",
    data() {
        return {
            isCameraOpen: false,
            isPhotoTaken: false
        }
    },
    mounted() {
        this.toggleCamera();
    },
    methods: {
    createCameraElement () {
        const constraints = (window.constraints = {
            audio: false,
            video: {
              facingMode: 'environment'
            }
            
        });
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                this.$refs.camera.srcObject = stream
                this.$refs.camera.onloadedmetadata = () => {
                  this.$refs.camera.play();
                };
            })
            .catch(error => {
                alert(error, "May the browser didn't support or there is some errors.")
            })
    },
    stopCameraStream () {
      const tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
      this.$emit('closeCamera')
    },
    toggleCamera () {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },
    takePhoto () {
      this.isPhotoTaken = !this.isPhotoTaken
      const photoFromVideo = this.$refs.camera
      this.$emit("takePhoto", photoFromVideo, this.stopCameraStream);
    },
    downloadImage() {
        const download = document.getElementById("downloadPhoto");
        const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
        download.setAttribute("href", canvas);
    }
  }
}

</script>

<style>
.camera {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-camera {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 330px;
  height: 360px;
  background-color: white;
}

.button {
  width: 140px;
  height: 40px;
}


.close_button {
    font-weight: 700;
    width: 60px;
}


.take-photo-button {
  font-weight: bold;
  font-size: 12px;
  margin: 0px 2px;
  text-align: center;
  width: 50%;
  border-style: none;
  height: 44px;
  color: white;
  background: black 0% 0% no-repeat padding-box;
}

.video-container {
  display: flex;
  flex-direction: column;
}
</style>