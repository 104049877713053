<template>
  <div class="confirmation-code-wrapper vh-100 vw-100">
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Código validador'" />
    <h1 class="header-confirmation-code">Seu código validador é:</h1>
    <div class="text-center">
      <loading v-model:active="loading" :can-cancel="false" :is-full-page="true"></loading>
      <ConfirmationCode v-for="code in data" :data="code" :key="code.confirmation_code"></ConfirmationCode>
      <ConfirmationCode v-if="data.length == 0"></ConfirmationCode>
      <button :disabled="loading" class="btn button-black rounded-button btn-search-confirmation-code"
        @click="this.getLastActiveConfirmationCode()">
        Buscar Código
      </button>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import ConfirmationCode from "./components/ConfirmationCode.vue"
import VueLoading from 'vue-loading-overlay';
import { apiService } from "@/util/api.service";
import { ref } from "vue";
import "@/assets/css/global.css"

export default {
  name: "LastActiveConfirmationCode",
  components: {
    HeaderLogo,
    ConfirmationCode,
    Loading: VueLoading
  },
  data() {
    return {
      data: {},
    }
  },
  setup() {
    const loading = ref(true);
    return {
      loading
    };
  },
  methods: {
    async getLastActiveConfirmationCode() {
      this.loading = true;
      apiService.getLastActiveConfirmationCode(
        (success) => {
          this.data = success.data;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    }
  },
  async mounted() {
    this.getLastActiveConfirmationCode();
  },
}
</script>

<style scoped>
.header-confirmation-code {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  margin-top: 16px;
}

.btn-search-confirmation-code {
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  width: 70%;
}

</style>