<template>
    <div class="dexBalancePage">
      <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Saldo/Extrato'"/>
      <h1>Saldo/Extrato</h1>
      <hr style="margin-bottom: 50px">
      <div v-for="dex in dexData" :key="dex" class="dex-balance-div">
        <DexBalance :data="dex"/>
      </div>
      <div class="button-back text-center">
        <button class="btn btn-circle button-black rounded-button" @click="$router.go(-1)">
          <BIconArrowLeft />
        </button>
      </div>
    </div>
</template>

<script>
  import { BIconArrowLeft } from "bootstrap-icons-vue";
  import HeaderLogo from "../../components/HeaderLogo.vue";
  import { apiService } from "@/util/api.service";

  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/pagination";
  import "@/assets/css/global.css"
  import DexBalance from "./components/DexBalance.vue"

  export default {
    name: "DexBalancePage",
    components: {
      HeaderLogo,
      DexBalance,
      BIconArrowLeft
    },
    props: {
      quota_id: String
    },
    data() {
      return {
          dexData: [],
      }
    },
    mounted() {
      apiService.getDexTransactions(this.quota_id, (response) => {
          response.data.forEach((dex) => {
              let object = {
                  id: dex.id,
                  asset_name: dex.asset_name,
                  value: dex.value,
                  date: dex.date,
                  description: dex.description
              }
              this.dexData.push(object);
          });
      });
    },
    created() {
      if (localStorage.getItem("user_type") != "Client") {
        this.$router.push("/");
      }
    },
  };
</script>

<style>
    h1 {
      font: normal normal bold 24px Helvetica Neue;
      color: black;
      text-align: center;
    }

    .dexBalancePage > hr {
      position:relative;
      background: #4E4E4E 0% 0% no-repeat padding-box;
      width:26px;
      height: 5px;
      opacity: 1;
      border-radius: 10px;
      margin: auto auto;
    }
    .dex-balance-div {
      margin-bottom: 0px;
    }
    .dex-balance-div:last-of-type {
      margin-bottom: 60px;
    }
</style>

<style scoped>
  #app {
    padding-bottom: 30px;
  }
  </style>