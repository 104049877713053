<template>
  <div class="camera-container">
    <h2 class="title text-center">Leitura do QR Code</h2>
    <div class="reader-container">
      <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" />
    </div>
    <div class="information text-center">
      <p v-if="!error">Aponte o leitor para o QR Code do bem</p>
      <div v-if="error">
        <span class="text-danger">QR Code inválido.</span>
        <p>
          Operador verifique se as informações do veículo é a mesma solicitada
          pelo cliente
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import { ref } from "vue";

export default {
  components: { StreamBarcodeReader },
  props: {
    code: { type: String },
  },
  methods: {
    onDecode(decodedText) {
      if (this.code == decodedText) {
        this.$emit("confirmQRCode");
      } else {
        this.error = true;
      }
    },
    onLoaded() {
      // console.log(this.code);
    },
  },
  setup() {
    const error = ref(false);
    return {
      error,
    };
  },
};
</script>

<style scoped>
.camera-container {
  margin: auto;
  width: 90%;
}

.reader-container {
  margin: 50px 20px 25px 20px;
}

.title {
  font-family: "Kanit";
  font-weight: bold;
  margin-top: 25px;
}

.information {
  padding: 0 30px 0 30px;
  font-family: "Kanit";
  font-weight: bold;
}
</style>