<template>
    <div class="simulator-choice">
      <GDialog :contentClass="'answer-result-modal'" v-model="modalState" max-width="500" transition="custom-from-bottom-transition">
        <div>
          <div class="content-modal">
            <div v-bind:class="[correct ? 'title title-success' : 'title title-failure']" ref="modalTitle">
              <svg v-if="correct" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" fill="#2CB029"/>
              </svg>
              <svg v-if="!correct" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z" fill="#C82E2E"/>
              </svg>
              {{correct ? "Resposta Correta!" : "Resposta Incorreta!"}}
              <div class="modal-close-button" @click="modalState = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L4 2.79289L6.64645 0.146447C6.84171 -0.0488155 7.15829 -0.0488155 7.35355 0.146447C7.54882 0.341709 7.54882 0.658291 7.35355 0.853553L4.70711 3.5L7.35355 6.14645C7.54882 6.34171 7.54882 6.65829 7.35355 6.85355C7.15829 7.04882 6.84171 7.04882 6.64645 6.85355L4 4.20711L1.35355 6.85355C1.15829 7.04882 0.841709 7.04882 0.646447 6.85355C0.451184 6.65829 0.451184 6.34171 0.646447 6.14645L3.29289 3.5L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z" fill="black"/>
                </svg>
              </div>
            </div>
            <hr/>
            <div v-if="correct" class="message">
              Muito bem. <strong>Você acertou!</strong>
            </div>
            <div v-if="!correct" class="message">
              <strong>A resposta correta é:</strong><br/>
              {{correct_answer["description"]}}
            </div>
            <hr/>
          </div>
          <div class="actions-modal actions">
            <button ref="modalButton" class="btn button-black rounded-button" @click="nextQuestion">
              {{button_text}}
            </button>
          </div>
        </div>
      </GDialog>
      <SimulatorHeader />
      <div class="progress">
        <div 
        :class="'progress-bar'"
        :style="(question_number/questions.length) * 100 < 7 ? 'width: 7%;' :'width:'+(question_number/questions.length) * 100+'%'" 
        role="progressbar" 
        :aria-valuenow="question_number" 
        aria-valuemin="0" 
        :aria-valuemax="questions.length"></div>
      </div>
      <div class="main-container">
        <div class="content-container">
          <div class="simulator-header">
            <p class="simulator-index">questão {{question_number}} de {{questions.length}}</p>
            <h1 class="simulator-title">{{questions[question_number] != undefined ? questions[question_number]["description"] : ""}}</h1>
          </div>
          <div v-if="questions[question_number] != undefined" class="card-container">
            <ol type="A">
                <li v-for="option, index in questions[question_number]['options']" :key="option.id">
                    <div @click="selectAnswer(index)" :class="selected==index? 'selected question-card' : 'question-card'"><span class="question-card-order">{{String.fromCharCode(index + 'A'.charCodeAt(0))}})</span>{{option.description}}</div>
                </li>
            </ol>
          </div>
          <button @click="nextQuestion" type="submit" class="btn rounded-button">{{button_text}}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SimulatorHeader from "./SimulatorHeader.vue";
  
  export default {
    name: "SimulatorQuestions",
    components: {
      SimulatorHeader,
    },
    data() {
        return {
            questions: [],
            answers: [],
            message: undefined,
            question_number: 0,
            selected: undefined,
            modalState: false,
            resultShown: false,
            correct: true,
            button_text: "Próxima Questão",
            showConfirmationModal: false,
        }
    },
    beforeRouteLeave(to, from, next) {
      if (from.path === "/simulado/perguntas" && to.path === "/simulado/escolha") {
        if (confirm("Tem certeza de que deseja voltar? Se você voltar, o progresso no simulado será perdido.")) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    },
    mounted() {
        this.questions = JSON.parse(localStorage.getItem("questions")) || [];
        if (localStorage.getItem("email") == undefined) {
            return this.$router.push("/simulado/escolha");
        }
        if (!this.questions) {
          return this.$router.push('/simulado/escolha');
        }
        const simulatorState = JSON.parse(localStorage.getItem("simulatorState"));
        if (simulatorState) {
            this.question_number = simulatorState.question_number || 1;
            this.answers = simulatorState.answers || [];
            this.selected = simulatorState.selected || undefined;
            this.modalState = simulatorState.modalState || false;
            this.correct = simulatorState.correct || true;
            this.button_text = simulatorState.button_text || "Próxima Questão";
        }
    },
    methods: {
        nextQuestion() {
          if (this.selected != undefined) {
            if (this.resultShown == false && this.modalState == false) {
              let answer = this.questions[this.question_number]["options"][this.selected]
              this.correct_answer = this.questions[this.question_number]["options"].filter((answer) => answer["correct"])[0];
              this.answers.push(answer["correct"]);
              if (answer["correct"]) {
                this.correct = true;
              } else {
                this.correct = false;
              }
              this.modalState = true;
              this.resultShown = true;
            } else {
              if (this.question_number < this.questions.length - 1) {
                  this.question_number++;
                  this.modalState = false;
                  this.selected = undefined;
                  localStorage.setItem("simulatorState", JSON.stringify({
                    question_number: this.question_number,
                    answers: this.answers,
                    selected: this.selected,
                    modalState: this.modalState,
                    correct: this.correct,
                    button_text: this.button_text
                  }));
                  this.resultShown = false;
              } else {
                this.$router.push({name:"simulator-result", params: {answers: this.answers}})
              }
              localStorage.setItem("simulatorState", JSON.stringify({
                question_number: this.question_number,
                answers: this.answers,
                selected: this.selected,
                modalState: this.modalState,
                correct: this.correct,
                button_text: this.button_text
            }));
            }
            this.button_text = this.question_number == this.questions.length - 1 ? "Resultado" : "Próxima Questão"; 
          }
        },
        selectAnswer(answer_index) {
          if (this.selected == undefined) {
            this.selected = answer_index;
          }
          if (this.resultShown == false) this.selected = answer_index
        },
    }
  };
  </script>
  
  <style scoped>
  .progress {
    border-radius: 0px;
    background-color: #ecf2ff;
  }

  .progress-bar {
    background-color: #5a85bd;
    height: 10px;
  }
  .content-modal {
    margin:0px;
    padding: 0px;
  }

  .simulator-index {
    margin: 0;
    font-size: 10px;
    color: #656d75;
  }

  .simulator-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .title {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .title-success {
    color: #2CB029;
  }
  .title-failure {
    color: #C82E2E;
  }
  .modal-close-button {
    margin-left: auto;
  }
  .actions-modal {
    margin: 0px;
    padding: 0px 16px 16px 16px;
  }

  .message {
    color: var(--Secondary-color, #6C757D);
    padding: 0px 16px 0px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }

  ol {
    padding-left: 0;
  }
  .simulator-title {
    color: #4D4D4D;
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    overflow-wrap: anywhere;
  }
  
  .content-container {
    display: flex;
    padding: 32px 24px;
    background-color: #ecf2ff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
  }
  
  .question-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white;
    border: 2px solid #0050B7;
    cursor: pointer;
    padding: 10px 16px;
    margin-top: 8px;
    font-family: 'DM Sans', sans-serif;
    line-height: 18px;
    overflow-wrap: anywhere;
  }
  
  .selected {
    background-color:#2CB029;
    color: white;
    border: 1px solid #63CF81;
  }

  .question-card-order {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    line-height: 18px;
    overflow-wrap: normal;
  }

  .card:active {
    transform: translateY(5px);
    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  }
  
  .card:focus {
    transform: translateY(5px);
    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  }
  
  .card img {
    width: 131px;
    height: 85px;
    flex-shrink: 0;
  }
  
  .card-title {
    color: #4D4D4D;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
  
  .rounded-button {
    display: flex;
    width: 100%;
    padding: 10px 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 6px;
    background: #004AA9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--Default-White, #FFF);
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
  }
  @media screen and (min-width: 768px) {
    .content-container {
      background-color: #ffffff;
      border-radius: 20px;
      padding-bottom: 37px;
    }

    .simulator-choice {
      background-color: #ecf2ff;
    }

    .simulator-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .main-container {
      background-color: #ffffff;
      margin: auto;
      width: 30%;
      min-width: 450px;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
      margin-top: 60px;
      border-radius: 20px;
    }
  
    .card {
      box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.20);
    }
  }
  </style>

  <style>
    .g-dialog-frame {
      backdrop-filter: blur(10px) !important;
    }

    .answer-result-modal {
      width: 342px !important;
      border-radius: 12px !important;
      border: 1px solid var(--Gray-400, #CED4DA);
      background: var(--Default-White, #FFF);
    }
    @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
  </style>