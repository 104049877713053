<template>
  <div class="reservedetails vh-100 vw-100">
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Minhas reservas'"/>
    <div class="text-center">
      <h1 class="title">Módulo de Reserva</h1>
      <div class="rectangle"></div>
    </div>
    <div class="asset">
      <AssetComponent :data="mockData" />
    </div>
    <div class="actions text-center">
      <button class="btn button-black rounded-button">
        <BIconDownload /> Salvar
      </button>
      <button class="btn button-black rounded-button" @click="back">
        <BIconXLg /> Cancelar
      </button>
    </div>
    <div class="button-back text-center">
      <button class="btn btn-circle button-black rounded-button" @click="back">
        <BIconArrowLeft />
      </button>
    </div>
  </div>
</template>

<script>
import { BIconArrowLeft, BIconDownload, BIconXLg } from "bootstrap-icons-vue";
import HeaderLogo from "../../components/HeaderLogo.vue";
import AssetComponent from "./components/AssetComponent.vue";

export default {
  name: "ReserveDetails",
  components: {
    HeaderLogo,
    AssetComponent,
    BIconArrowLeft,
    BIconDownload,
    BIconXLg,
  },
  methods: {
    back() {
      this.$router.push("/quotas");
    },
  },
  setup() {
    return {
      mockData: {
        name: "jet ski sea doo rxt 230 2018",
      },
    };
  },
};
</script>

<style scoped>
.asset {
  margin: 25px;
}

.title {
  font: normal normal 900 24px/28px Helvetica Neue;
  color: black;
  letter-spacing: 0px;
  margin-bottom: -8px;
}

.rectangle {
  height: 5px;
  width: 26px;
  border-radius: 10px;
  display: inline-block;
  background: #4e4e4e 0% 0% no-repeat padding-box;
}

.actions {
  position:relative;
  bottom: 12px;
}

.actions button {
  min-width: 104px;
  padding: 5px;
}

.button-back {
  position:relative;
  bottom: 8px;
  padding-bottom: 20px;
}

.btn:hover {
  color: white;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>
