<template>
  <div class="operator-header headerlogo">
    <OperatorSideMenu v-if="showSideMenu"/>
    <div class="black-variant text-center">
      <img src="@/assets/img/logo_white.png" class="img-fluid" />
    </div>
  </div>
</template>

<script>
import OperatorSideMenu from "./OperatorSideMenu.vue";

export default {
  name: "OperatorHeader",
  props: {
    whiteVariant: { required: false, type: Boolean, default: true },
    showSideMenu: { required: false, type: Boolean, default: false },
  },
  components: {
    OperatorSideMenu,
  },
};
</script>

<style scoped>
img {
  width: 170px;
}

.headerlogo {
  background-color: black;
}

.white-variant img {
  padding: 70px 20px 20px 20px;
}

.black-variant img {
  padding: 33px 20px 20px 20px;
}

.black-variant {
  /* height: 165px; */
  height: 130px;
  width: 200px;
  background: black;
}
</style>
